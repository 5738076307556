import { fetchUtils } from "react-admin";
import { HttpStatus } from "../enum/HttpStatus";
import { EErrorCode } from "../enum/EErrorCode";
import authProvider from "../synapse/authProvider";

const http = {
  fetch(url, options = {}) {
    const authorization = options.headers?.get("Authorization");
    const token = localStorage.getItem("access_token");
    if (token !== null) {
      options.user = {
        authenticated: true,
        token: authorization ?? `Bearer ${token}`,
      };
    }
    return fetchUtils.fetchJson(url, options).catch(error => {
      const { status, body } = error;
      const isForbiddenError =
        status === HttpStatus.FORBIDDEN &&
        body?.errcode === EErrorCode.M_ACCESS_FORBIDDEN;
      if (
        token !== null &&
        (isForbiddenError || status === HttpStatus.UNAUTHORIZED)
      ) {
        if (!options.skipLogout) {
          authProvider.logout();
        }
      }
      return Promise.reject(error);
    });
  },

  async request(url, method, body, headers) {
    const config = {
      method,
    };

    if (headers && headers instanceof Headers) {
      config.headers = headers;
    }

    // Only stringify body if it's not FormData
    if (body && !(body instanceof FormData)) {
      config.body = JSON.stringify(body);
    } else if (body instanceof FormData) {
      config.body = body;
    }

    return await this.fetch(url, config);
  },

  async get(url, headers) {
    return await this.request(url, "GET", null, headers);
  },

  async post(url, body, headers) {
    return await this.request(url, "POST", body, headers);
  },

  async put(url, body, headers) {
    return await this.request(url, "PUT", body, headers);
  },

  async patch(url, body, headers) {
    return await this.request(url, "PATCH", body, headers);
  },

  async delete(url, headers) {
    return await this.request(url, "DELETE", null, headers);
  },
};

export default http;
