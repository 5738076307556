import {
    useTranslate,
    useNotify,
    Loading,
    Confirm,
} from "react-admin";
import React, { useEffect, useState } from "react";
import { ThemeProvider, Box, Button } from "@mui/material";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import FormControl from "@material-ui/core/FormControl";
import { MenuItem, Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import muiTheme from "../../../helper/muiTheme";
import { Pagination } from "../../common/Pagination";
import settingProvider from "../../../synapse/settingProvider";
import IpAddressDialog from "./IpAddressDialog";
import IpAddressDetailsDialog from "./IpAddressDetailsDialog";
import PropTypes from "prop-types";

function isBlacklist(row) {
    return row.type === 'blacklist';
}

function getOppositeIPType(row) {
    return isBlacklist(row) ? 'Whitelist' : 'Blacklist';
}

const typeFilterOptions = [
    'All',
    'Whitelist',
    'Blacklist',
];

const EditToolbar = (props) => {
    const translate = useTranslate();
    const {
        onBackToSettings,
        onOpenIpAddressDialog,
        onChangeTypeFilter,
    } = props;

    return (
        <GridToolbarContainer
            sx={{
                justifyContent: 'space-between',
                flexFlow: 'column',
                alignItems: 'flex-start',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    marginTop: '10px'
                }}
            >
                <Button
                    component="label"
                    variant="text"
                    startIcon={<ArrowBackIcon/>}
                    onClick={onBackToSettings}
                    >
                        {translate("resources.settings.labels.back_to_settings")}
                </Button>
                <div
                    style={{
                        display: 'flex',
                        width: '60%',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '70%',
                            justifyContent: 'space-between'
                        }}
                    >
                        <GridToolbarQuickFilter
                            quickFilterParser={(searchInput) =>
                                searchInput.split(',').map((value) => value)
                            }
                            quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
                            debounceMs={500} // time before applying the new quick filter value
                            variant="outlined"
                            size="small"
                            sx={{ marginRight: '10px' }}
                        />
                        <FormControl
                            variant="outlined"
                            style={{width: '50%'}}
                            required={true}
                            size="small"
                        >
                            <InputLabel id="ip-address-type">{translate('resources.settings.fields.ip_type')}</InputLabel>
                            <Select
                                labelId='ip-address-type-label'
                                id='ip-address-type'
                                variant='outlined'
                                defaultValue={typeFilterOptions[0].toLowerCase()}
                                label={translate('resources.settings.fields.ip_type')}
                                onChange={onChangeTypeFilter}
                            >
                            {
                                typeFilterOptions.map((type) => (
                                    <MenuItem key={type.toLowerCase()} value={type.toLowerCase()}>
                                        {translate(`resources.settings.labels.list_ip_type_${type.toLowerCase()}`)}
                                    </MenuItem>
                                ))
                            }
                            </Select>
                        </FormControl>
                    </div>
                    <Button
                        component="label"
                        variant="contained"
                        startIcon={<AddIcon/>}
                        onClick={onOpenIpAddressDialog}
                    >
                        {translate("resources.settings.labels.list_ip_address_add")}
                    </Button>
                </div>
            </div>
        </GridToolbarContainer>
    );
}

EditToolbar.propTypes = {
    onBackToSettings: PropTypes.func.isRequired,
    onOpenIpAddressDialog: PropTypes.func.isRequired,
    onChangeTypeFilter: PropTypes.func.isRequired,
}

const ListIPAddress = ({ onBackToSettings }) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [listData, setListData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [confirmToggleType, setConfirmToggleType] = useState({
        isOpen: false,
        ip: '',
        feature: '',
        type: '',
        title: '',
        content: '',
    });
    const [confirmDelete, setConfirmDelete] = useState({
        isOpen: false,
        ip: '',
        feature: '',
        title: '',
        content: '',
    });
    const [ipAddressDialog, setIpAddressDialog] = useState({
        isOpen: false,
        ip: '',
        feature: '',
        type: '',
        note: '',
    });

    const [viewDetailsDialog, setViewDetailsDialog] = useState({
        isOpen: false,
        ipAddress: '',
        metadata: '',
    });

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns = [
        {
            field: 'ip',
            headerName: 'IP address',
            width: 250,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: 'feature',
            headerName: 'Feature',
            width: 100 ,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            sortable: true,
            disableColumnMenu: true,
            type: 'singleSelect',
            valueOptions: ['whitelist', 'blacklist'],
            renderCell: (params) => (
                <div>
                    {
                        params.value === 'blacklist' && <span style={{color: 'red'}}>
                            {params.value}
                        </span>
                    }
                    {
                        params.value === 'whitelist' && <span style={{color: 'green'}}>
                            {params.value}
                        </span>
                    }
                </div>
            )
        },
        {
            field: 'createdAt',
            headerName: 'Created at',
            width: 200,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            type: 'actions',
            headerName: 'Details',
            width: 100,
            sortable: false,
            disableColumnMenu: true,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <Button
                        key='view'
                        component="label"
                        color="primary"
                        disabled={!row.metadata}
                        onClick={(e) => onOpenViewDetailsDialog(row, e)}
                    >View</Button>
                ];
            }
        },
        {
            field: 'note',
            headerName: 'Note',
            width: 200 ,
            sortable: true,
            disableColumnMenu: true,
        },
        {
            field: 'actions',
            type: 'actions',
            // headerName: 'Actions',
            width: 250,
            cellClassName: 'actions',
            getActions: ({ row }) => {
                return [
                    <Button
                      key="ip_type"
                      component="label"
                      variant="outlined"
                      color="primary"
                      onClick={() => onToggleType(row)}
                    >{getOppositeIPType(row)} IP</Button>,
                    <Button
                        key="delete"
                        component="label"
                        variant="outlined"
                        color="error"
                        onClick={() => onRemoveIp(row)}
                    >Delete</Button>
                ];
            },
          },
    ];

    const fetchData = async () => {
        setLoading(true);
        settingProvider.getIPList().then((res) => {
            setFetchedData(res?.data);
            setListData(res?.data);
        }).catch(e => {
            notify(e.message, { type: "error" });
        }).finally(() => {
            setLoading(false);
        });
    }

    const onRowsPerPageChange = (event) => {
        if (event.pageSize) {
            setPageSize(event.pageSize);
        }
    };

    const onConfirmRemoveIp = () => {
        setLoading(true);
        settingProvider.deleteIPFromList(confirmDelete.ip, confirmDelete.feature).then(() => {
            fetchData();
        }).catch(e => {
            notify(e.message || e.body?.error, { type: "error" });
        }).finally(() => {
            setLoading(false);
            setConfirmDelete({ isOpen: false });
        });
    };

    const onConfirmToggleIpType = () => {
        setLoading(true);
        settingProvider.updateIPAddress({
            ip: confirmToggleType.ip,
            feature: confirmToggleType.feature,
            type: confirmToggleType.type,
        }).then(() => {
            notify('Update success', { type: "success" });
            // noinspection JSIgnoredPromiseFromCall
            fetchData();
        }).catch(e => {
            notify(e.message, { type: "error" });
        }).finally(() => {
            setLoading(false);
            setConfirmToggleType({ isOpen: false });
        });
    };

    const onOpenIpAddressDialog = (row = {}) => {
        setIpAddressDialog({
            isOpen: true,
            ...row
        });
    };

    const onEditIpAddress = (e) => {
        setIpAddressDialog({
            isOpen: true,
            ...e.row
        });
    };

    const onCloseIpAddressDialog = (saveSucess) => {
        setIpAddressDialog({
            ...ipAddressDialog,
            isOpen: false
        });
        if (saveSucess) {
            fetchData();
        }
    };

    const onOpenViewDetailsDialog = (row, e) => {
        e?.preventDefault();
        e?.stopPropagation();
        setViewDetailsDialog({
            isOpen: true,
            ipAddress: row.ip,
            metadata: row.metadata,
        });
    };

    const onCloseViewDetailsDialog = () => {
        setViewDetailsDialog({
            isOpen: false,
            metadata: ''
        });
    };

    const onRemoveIp = (row) => {
        setConfirmDelete({
            isOpen: true,
            ip: row?.ip,
            feature: row?.feature,
            title: `Delete ${row?.ip}`,
            content: "Do you want to delete this ip address?",
        });
    };

    const onToggleType = (row) => {
        const action = getOppositeIPType(row);
        setConfirmToggleType({
            isOpen: true,
            ip: row?.ip,
            feature: row?.feature,
            title: `${action} ${row?.ip}`,
            type: action.toLowerCase(),
            content: `Do you want to ${action.toLowerCase()} IP ${row.ip}?`,
        });
    }

    const handleDialogClose = (setFn) => {
        setFn({
            isOpen: false,
        });
    };

    const onChangeTypeFilter = (e) => {
        const valueTypeFilter = e.target?.value;
        switch (valueTypeFilter) {
            case 'all':
                setListData(fetchedData);
                break;
            case 'whitelist':
                setListData(fetchedData.filter((item) => item?.type === 'whitelist'));
                break;
            case 'blacklist':
                setListData(fetchedData.filter((item) => item?.type === 'blacklist'));
                break;
            default:
                // Do nothing
                break;
        }
    };

    if (loading) return <Loading />;
    return (
        <ThemeProvider theme={muiTheme}>
            <Box
                sx={{
                    height: '75vh',
                    width: '100%',
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                    marginBottom: '10px',
                }}
            >
                <DataGrid
                    rows={listData}
                    columns={columns}
                    isRowSelectable={() => false}
                    getRowId={(row) => row?.ip}
                    onRowClick={onEditIpAddress}
                    initialState={{
                        pagination: { paginationModel: { pageSize: pageSize } },
                    }}
                    pageSizeOptions={[10, 25, 50]}
                    onPaginationModelChange={(event) => onRowsPerPageChange(event)}
                    slots={{
                        pagination: Pagination,
                        toolbar: EditToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            onBackToSettings,
                            onOpenIpAddressDialog,
                            onChangeTypeFilter,
                        },
                    }}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
                            whiteSpace: 'unset',
                            overflowWrap: 'break-word',
                            overflowY: 'auto',
                        },
                        '.MuiDataGrid-columnHeader:focus': {
                            outline: 'none'
                        },
                        // disable cell selection style
                        '.MuiDataGrid-cell:focus': {
                            outline: 'none'
                        },
                        // pointer cursor on ALL rows
                        '& .MuiDataGrid-row:hover': {
                            cursor: 'pointer'
                        }
                    }}
                />
            </Box>
            <Confirm
                isOpen={confirmDelete.isOpen}
                title={confirmDelete.title}
                content={confirmDelete.content}
                onConfirm={onConfirmRemoveIp}
                onClose={() => handleDialogClose(setConfirmDelete)}
            />
            <Confirm
              isOpen={confirmToggleType.isOpen}
              title={confirmToggleType.title}
              content={confirmToggleType.content}
              onConfirm={onConfirmToggleIpType}
              onClose={() => handleDialogClose(setConfirmToggleType)}
            />
            <IpAddressDialog
                data={ipAddressDialog}
                onClose={() => onCloseIpAddressDialog(false)}
                onSaveSuccess={() => onCloseIpAddressDialog(true)}
            />
            <IpAddressDetailsDialog
                data={viewDetailsDialog}
                onClose={onCloseViewDetailsDialog}
            />
        </ThemeProvider>
    );
}
ListIPAddress.propTypes = {
    onBackToSettings: PropTypes.func.isRequired,
}
export default ListIPAddress;
