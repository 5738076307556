import HttpAuth from "../utils/httpAuth";

const profileProvider = {
  updatePassword(dto) {
    return HttpAuth.post("/user/change-password", dto);
  },
  twoFactorEnable() {
    return HttpAuth.post("/user/two-factor/enable");
  },
  verifyTwoFactor(otpCode) {
    return HttpAuth.post("/user/two-factor/verify", { otpCode });
  },
};

export default profileProvider;
