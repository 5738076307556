import React, { useEffect, useState } from "react";
import {
  fetchUtils,
  FormDataConsumer,
  Notification,
  useNotify,
  useTranslate,
  PasswordInput,
  TextInput,
  required,
  minLength,
} from "react-admin";
import { Form, useForm } from "react-final-form";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CircularProgress,
  TextField,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LockIcon from "@material-ui/icons/Lock";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { Steps } from "../../enum/ELoginFlow";
import adminProvider from "../../synapse/adminProvider";
import {
  setServerConfigs,
  getServerConfig,
  setTitle,
  fetchHomeseverOptions,
  defaultServerOption,
  HOME_SERVER_NAME,
  getServerConfigsByDefault,
} from "../../helper/serverConfig";

const useStyles = makeStyles(theme => ({
  main: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 1em)",
    alignItems: "center",
    justifyContent: "flex-start",
    background: "url(./images/floating-cogs.svg)",
    backgroundColor: "#f9f9f9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },
  card: {
    minWidth: "20em",
    marginTop: "6em",
    marginBottom: "6em",
    width: "25%",
  },
  avatar: {
    paddingTop: "2em",
    margin: "1em",
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
  },
  hint: {
    marginTop: "1em",
    display: "flex",
    justifyContent: "center",
    color: theme.palette.grey[500],
  },
  form: {
    padding: "1em 1em 0em 1em",
  },
  input: {
    marginBottom: "0.5em",
  },
  actions: {
    padding: "0 1em 1em 1em",
  },
  serverVersion: {
    color: "#9e9e9e",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    marginBottom: "1em",
    marginLeft: "0.5em",
  },
  select: {
    paddingBottom: "1.5em",
  },
  serverCircularProgress: {
    textAlign: "center",
    padding: "0.5em 0em",
  },
  text_error: {
    textAlign: "center",
    paddingBottom: "1.5em",
    color: "red",
  },
}));

const LoginStep = props => {
  const classes = useStyles({ theme: props.theme });

  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const [homeServerOptions, setHomeServerOptions] =
    useState(defaultServerOption);
  const [homeServerName, setHomeServerName] = useState("");
  const [loadingServer, setLoadingServer] = useState(false);
  const [failFetchConfig, setFailFetchConfig] = useState(true);

  const translate = useTranslate();

  useEffect(() => {
    fetchHomeseverOptions()
      .then(response => {
        setHomeServerOptions(response?.json);
      })
      .catch(e => {
        getServerConfigsByDefault();
      });
  }, []);

  useEffect(() => {
    const defaultServerName = homeServerOptions[0]?.server_name;
    localStorage.setItem(HOME_SERVER_NAME, defaultServerName);
    setHomeServerName(defaultServerName);
    setInforHomeServer(defaultServerName);
  }, [homeServerOptions]);

  const setInforHomeServer = serverName => {
    setTitle(serverName);
    setLoadingServer(true);
    const base_url = homeServerOptions?.find(
      e => e.server_name === serverName
    )?.base_url;
    setServerConfigs(base_url, homeServerName)
      .then(success => {
        if (success) {
          setFailFetchConfig(false);
        } else {
          setFailFetchConfig(true);
        }
      })
      .finally(() => {
        setLoadingServer(false);
      });
  };

  const onChangeHomeServer = serverName => {
    localStorage.setItem(HOME_SERVER_NAME, serverName);
    setHomeServerName(serverName);
    setInforHomeServer(serverName);
  };

  const validate = values => {
    const errors = {};
    if (!values.username) {
      errors.username = translate("ra.validation.required");
    }
    if (!values.password) {
      errors.password = translate("ra.validation.required");
    }
    if (!values.base_url) {
      errors.base_url = translate("ra.validation.required");
    } else {
      if (!values.base_url.match(/^(http|https):\/\//)) {
        errors.base_url = translate("synapseadmin.auth.protocol_error");
      } else if (
        !values.base_url.match(
          /^(http|https):\/\/[a-zA-Z0-9\-.]+(:\d{1,5})?[^?&\s]*$/
        )
      ) {
        errors.base_url = translate("synapseadmin.auth.url_error");
      }
    }
    return errors;
  };

  const handleSubmit = async auth => {
    try {
      setLoading(true);
      const dto = {
        step: Steps.LOGIN,
        user: auth.username,
        password: auth.password,
      };
      const authUrl = getServerConfig("auth_server_url");
      const res = await adminProvider.loginStep(dto, authUrl);
      const result = res?.json;
      props.onUpdateAccount({
        username: auth.username,
        password: auth.password,
        displayname: result?.displayname,
      });
      props.handleSubmit(res?.json);
    } catch (error) {
      if (error.body?.errcode === "M_USER_DEACTIVATED") {
        notify("synapseadmin.auth.login_flow.user_deactivated", "error");
        return;
      }
      notify(
        typeof error === "string"
          ? error
          : typeof error === "undefined" || !error.message
          ? "ra.auth.sign_in_error"
          : error.message,
        "warning"
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      initialValues={{ base_url: homeServerOptions[0]?.base_url }}
      onSubmit={handleSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <div className={classes.avatar}>
                <Avatar className={classes.icon}>
                  <LockIcon />
                </Avatar>
              </div>
              <div className={classes.hint}>
                {translate("synapseadmin.auth.welcome_brand", {
                  brand: homeServerName || "Synapse",
                })}
              </div>
              <div className={classes.form}>
                <FormDataConsumer>
                  {formDataProps => (
                    <UserData
                      {...formDataProps}
                      onChangeServer={onChangeHomeServer}
                      homeServerName={homeServerName}
                      loading={loading}
                      loadingServer={loadingServer}
                      homeServerOptions={homeServerOptions}
                      failFetchConfig={failFetchConfig}
                    />
                  )}
                </FormDataConsumer>
              </div>
              <CardActions className={classes.actions}>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading || failFetchConfig}
                  className={classes.button}
                  fullWidth
                >
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {translate("ra.auth.sign_in")}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

LoginStep.propTypes = {
  theme: PropTypes.object,
  handleSubmit: PropTypes.func,
  onUpdateAccount: PropTypes.object,
};

const UserData = props => {
  const form = useForm();
  const classes = useStyles({ theme: props.theme });
  const translate = useTranslate();

  const onChangeServer = value => {
    form.reset();
    props.onChangeServer(value);
  };

  const renderInput = ({
    meta: { touched, error } = {},
    input: { ...inputProps },
    ...props
  }) => (
    <TextField
      error={!!(touched && error)}
      helperText={touched && error}
      {...inputProps}
      {...props}
      fullWidth
    />
  );

  return (
    <div>
      {props.homeServerOptions?.length > 1 && (
        <FormControl
          variant="outlined"
          className={classes.select}
          fullWidth
          required={true}
          size="small"
        >
          <InputLabel id="choose_server_label">
            {translate("synapseadmin.auth.choose_server")}
          </InputLabel>
          <Select
            id="choose_server"
            variant="outlined"
            value={props.homeServerName}
            label={translate("synapseadmin.auth.choose_server")}
            disabled={props.loading}
            onChange={e => onChangeServer(e.target.value)}
          >
            {props.homeServerOptions?.map(e => (
              <MenuItem key={e.server_name} value={e.server_name}>
                {e.server_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {props.loadingServer ? (
        <div className={classes.serverCircularProgress}>
          <CircularProgress size={25} thickness={2} />
        </div>
      ) : (
        <div>
          {props.failFetchConfig ? (
            <div className={classes.text_error}>
              {translate("synapseadmin.auth.failed_to_fetch_config")}
            </div>
          ) : (
            <div>
              <div className={classes.input}>
                <TextInput
                  autoFocus
                  name="username"
                  component={renderInput}
                  label={"resources.profile.fields.username"}
                  disabled={props.loading}
                  // onBlur={handleUsernameChange}
                  resettable
                  fullWidth
                />
              </div>
              <div className={classes.input}>
                <PasswordInput
                  label={"resources.profile.fields.password"}
                  name="password"
                  source="password"
                  variant="outlined"
                  autoComplete="new-password"
                  disabled={props.loading}
                  fullWidth
                  validate={[
                    required("resources.profile.fields.password_required"),
                    minLength(6, "resources.profile.fields.minlength_password"),
                  ]}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

UserData.propTypes = {
  theme: PropTypes.object,
  onChangeServer: PropTypes.func,
  homeServerName: PropTypes.string,
  loading: PropTypes.bool,
  loadingServer: PropTypes.bool,
  homeServerOptions: PropTypes.array,
  failFetchConfig: PropTypes.bool,
};

export default LoginStep;
