import HttpAuth from "../utils/httpAuth";

const serverNoticeProvider = {
  serverNoticeSendBulkMessage(dto) {
    return HttpAuth.post("/server-notice/send-message", dto);
  },
  sendServerNoticeAllUser(message) {
    return HttpAuth.post("/server-notice/send-message/all", { text: message });
  },
};

export default serverNoticeProvider;
