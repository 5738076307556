import { stringify } from "query-string";
import { fetchFile } from "../utils/fetch";
import HttpAuth from "../utils/httpAuth";
import HttpBotGateway from "../utils/httpBotGateway";
import { getServerConfig } from "../helper/serverConfig";
import http from "../utils/http";

function getSearchOrder(order) {
  if (order === "DESC") {
    return "b";
  } else {
    return "f";
  }
}

const botProvider = {
  getList(res, params) {
    const { search, enable, isPublic, container } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const from = (page - 1) * perPage;
    const query = {
      size: perPage,
      page: page,
      search: search?.trim(),
      enable: enable,
      public: isPublic,
      containerId: container && container === "All" ? undefined : container,
      order_by: field,
      dir: getSearchOrder(order),
    };
    return HttpAuth.get(`/bots?${stringify(query)}`).then(({ json }) => ({
      data: json["items"].map((item, index) => res.map(item, index, from)),
      total: res.total(json, from, perPage),
    }));
  },

  getBot(botId) {
    return HttpAuth.get(`/bots/${botId}`).then(({ json }) => ({
      data: json,
    }));
  },

  createBot(data) {
    let formData = new FormData();
    formData.append("name", data.name);
    formData.append("description", data.description);
    formData.append("avatar", data.avatar);
    formData.append("containerId", data.containerId);
    formData.append("isPublic", data.isPublic ?? false);

    return HttpAuth.post("/bots", formData, {
      "Content-Type": "multipart/form-data;",
    });
  },

  updateBot(data) {
    let formData = new FormData();
    for (var key in data) {
      if (data.hasOwnProperty(key) && key !== "id") {
        formData.append(key, data[key]);
      }
    }

    return HttpAuth.put(`/bots/${data.id}`, formData, {
      "Content-Type": "multipart/form-data;",
    });
  },

  deleteBot(botId) {
    return HttpAuth.delete(`/bots/${botId}`);
  },

  generateShareKey(botId) {
    return HttpAuth.post(`/share/bot/presigned-key/${botId}`);
  },

  generateBotToken(botId) {
    return HttpAuth.put(`/bots/${botId}/gen-bot-token`);
  },

  getListRoom(botId) {
    return HttpAuth.get(`/bots/${botId}/rooms`);
  },

  getQueueInfo(botId) {
    return HttpAuth.get(`/bots/queue/${botId}/info`);
  },

  leaveRooms(botId, roomIds) {
    let ids = roomIds;
    if (typeof ids === "string") {
      ids = [roomIds];
    }
    return HttpAuth.put(`/bots/remove-from-rooms/${botId}`, { roomIds: ids });
  },

  getContainers() {
    return HttpAuth.get("/bots/containers");
  },

  sendForm(botToken, signature, data) {
    let formData = new FormData();
    formData.append("from", data.from);
    formData.append("to", data.to);
    formData.append("description", data.description);
    if (data.metadata) {
      formData.append("metadata", data.metadata);
    }
    if (data.file?.length) {
      for (const f of data.file) {
        formData.append("file", f);
      }
    }
    formData.append("form", JSON.stringify(data.form));

    const headers = new Headers({
      "x-signature": `${signature}`,
    });

    return HttpBotGateway.post(`/v1/bot/${botToken}/form`, formData, headers);
  },

  send(config) {
    let body;
    if (config.data && ["POST", "PUT", "PATCH"].includes(config.method)) {
      body = JSON.stringify(config.data);
    }
    return http.fetch(config.url, {
      method: config.method,
      headers: new Headers({
        "Content-Type": "application/json",
      }),
      body: body,
    });
  },

  getListBotResponse(botId, type) {
    return HttpAuth.get(`/bots/${botId}/callback/${type}`).then(({ json }) => ({
      data: json?.map((item, index) => {
        return {
          ...item,
          id: index,
          metadata: JSON.stringify(item.metadata),
          headers: item.headers,
          created_at:
            item.created_at && item.created_at instanceof Date
              ? item.created_at
              : new Date(item.created_at),
          response_at:
            item.response_at && item.response_at instanceof Date
              ? item.response_at
              : new Date(item.response_at),
        };
      }),
    }));
  },

  /**
   * Delete list response for form and proxy
   *
   * @param botId string
   * @param type form|proxy
   */
  deleteListResponses(botId, type) {
    return HttpAuth.delete(`/bots/${botId}/callback/${type}`);
  },

  getBotFormResponseDetails(botToken, signature, data) {
    const headers = new Headers({
      "x-signature": `${signature}`,
      "content-type": "application/json; charset=utf-8",
    });
    return HttpBotGateway.post(
      `/v1/bot/${botToken}/form/response`,
      data,
      headers
    );
  },

  downloadFileFromResponse(botToken, signature, requestBody, fileName) {
    const url =
      getServerConfig("bot_gateway_url") + `/v1/bot/${botToken}/form/file`;
    const options = {
      method: "POST",
      headers: new Headers({
        "x-signature": `${signature}`,
      }),
      body: JSON.stringify(requestBody),
    };
    return fetchFile(url, options, fileName);
  },

  testCallback(url, data, testOpt) {
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      skipLogout: true,
    };
    options.headers = new Headers();
    if (testOpt.signature) {
      options.headers.append("x-signature", testOpt.signature);
    }
    if (testOpt.token) {
      const tokenParts = testOpt.token.split(" ");
      const token =
        tokenParts.length > 1 ? tokenParts : `Bearer ${tokenParts[0]}`;
      options.headers.append("Authorization", token);
    }
    return http.fetch(url, options);
  },

  proxyResponse(botToken, requestBody) {
    return HttpBotGateway.post(
      `/v1/bot/${botToken}/proxy/response`,
      requestBody
    );
  },
};

export default botProvider;
