import HttpAuth from "../utils/httpAuth";

const isArrayInputDomains = key => ["ALLOW_CLOUDKIT_DOMAINS"].includes(key);

const settingProvider = {
  async getSettings() {
    const {
      json: { data },
    } = await HttpAuth.get("/settings/system");
    const settings = {};
    data.forEach(({ key, value }) => {
      settings[key] = value;
      if (key.startsWith("LOGIN_MANUAL")) {
        settings["_loginManual"] = !!value.length;
      } else if (isArrayInputDomains(key)) {
        settings[key] = value.map(d => ({ domain: d }));
      }
    });
    return settings;
  },
  saveSettings({ data }) {
    const configs = [];
    Object.keys(data).forEach(key => {
      if (key.startsWith("_")) {
        if (key === "_loginManual" && !data[key]) {
          data["LOGIN_MANUAL"] = [];
          configs.push({
            key: "LOGIN_MANUAL",
            value: [],
          });
        }
        return;
      }
      if (isArrayInputDomains(key)) {
        configs.push({
          key: key,
          value: data[key].map(input => input.domain),
        });
        return;
      }
      configs.push({
        key: key,
        value: data[key],
      });
    });
    return HttpAuth.put("/settings/system", configs);
  },
  executeCron(key) {
    return HttpAuth.put("/settings/system/cron/execute", { key });
  },
  getIPList() {
    return HttpAuth.get("/settings/system/ip-list").then(({ json }) => ({
      data: json?.map((item, index) => {
        return {
          ...item,
          id: index,
          createdAt:
            item.createdAt instanceof Date
              ? item.createdAt
              : new Date(item.createdAt).toLocaleString(),
        };
      }),
    }));
  },
  addIPAddress(data) {
    const dto = {
      ip: data.ip,
      type: data.type,
      feature: data.feature,
      note: data.note,
    };
    return HttpAuth.post("/settings/system/ip-list", dto);
  },
  updateIPAddress(data) {
    const dto = {
      ip: data.ip,
      type: data.type,
      feature: data.feature,
      note: data.note,
    };
    return HttpAuth.put("/settings/system/ip-list", dto);
  },
  deleteIPFromList(ip, feature) {
    return HttpAuth.put("/settings/system/ip-list/remove", {
      ip,
      feature: feature,
    });
  },
};

export default settingProvider;
