import { fetchUtils } from "react-admin";
import { HttpStatus } from "../enum/HttpStatus";
import { getMetaData, delMetaData } from "../utils/storage";
import { getServerConfig } from "../helper/serverConfig";
import HttpHomeserver from "../utils/httpHomeserver";

const authProvider = {
  // called when the user attempts to log in
  login: ({ loginToken, type }) => {
    // force homeserver for protection in case the form is manipulated
    let base_url = getServerConfig("home_server_url");

    const options = {
      method: "POST",
      body: JSON.stringify(
        Object.assign(
          {
            device_id: localStorage.getItem("device_id"),
            initial_device_display_name: "Synapse Admin",
          },
          {
            type: type || "m.login.token",
            token: loginToken,
          }
        )
      ),
    };

    // use the base_url from login instead of the well_known entry from the
    // server, since the admin might want to access the admin API via some
    // private address
    base_url = base_url.replace(/\/+$/g, "");

    const decoded_base_url = window.decodeURIComponent(base_url);
    const login_api_url = decoded_base_url + "/_matrix/client/r0/login";

    return fetchUtils.fetchJson(login_api_url, options).then(({ json }) => {
      localStorage.setItem("home_server", json.home_server);
      localStorage.setItem("user_id", json.user_id);
      localStorage.setItem("access_token", json.access_token);
      localStorage.setItem("device_id", json.device_id);
      if (!getMetaData("requiredChangePassword")) {
        window.location = "/";
      }
    });
  },
  createScalarToken: cal => {
    const dimensionUrl = getServerConfig("dimension_url");

    const getMatrixOpenIdData = cal => {
      const userId = localStorage.getItem("user_id");
      HttpHomeserver.post(
        `/_matrix/client/r0/user/${userId}/openid/request_token`,
        {}
      )
        .then(({ json }) => {
          cal && cal(json);
        })
        .catch(e => {
          cal && cal(null, e);
        });
    };

    const getScalarToken = cal => {
      getMatrixOpenIdData(openIdData => {
        fetchUtils
          .fetchJson(`${dimensionUrl}/api/v1/scalar/register?v=1.1`, {
            method: "POST",
            body: JSON.stringify({
              state: "allowed",
              token_type: "Bearer",
              ...openIdData,
            }),
          })
          .then(({ json }) => {
            cal(json.scalar_token);
          })
          .catch(e => {
            cal(null, e);
          });
      });
    };

    getScalarToken((scalarToken, error) => {
      localStorage.setItem("scalar_token", scalarToken);
      cal(scalarToken, error);
    });
  },
  connectDimensionServer: (scalarToken, cal) => {
    const dimensionUrl = getServerConfig("dimension_url");
    fetchUtils
      .fetchJson(
        `${dimensionUrl}/api/v1/dimension/admin/stickers/packs?scalar_token=${scalarToken}`,
        {
          method: "GET",
        }
      )
      .then(({ json }) => {
        cal(true);
      })
      .catch(e => {
        //retry to connect with new scalar token
        cal(null, e);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    HttpHomeserver.post("/_matrix/client/r0/logout", {}).then();
    localStorage.removeItem("access_token");
    delMetaData("requiredChangePassword");
    localStorage.removeItem("device_id");
    localStorage.removeItem("home_server");
    localStorage.removeItem("meta_data");
    localStorage.removeItem("user_id");
    localStorage.removeItem("scalar_token");
    localStorage.removeItem("home_server_name");
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === HttpStatus.FORBIDDEN) {
      return Promise.reject({
        redirectTo: "/not_found",
        logoutUser: false,
        message: false,
      });
    }
    if (status === HttpStatus.UNAUTHORIZED) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    const access_token = localStorage.getItem("access_token");
    const requiredChangePassword = getMetaData("requiredChangePassword");
    return typeof access_token === "string" && !requiredChangePassword
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/login", message: false });
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
