import HttpAuth from "../utils/httpAuth";

const roomProvider = {
  async updateRoomPermissions(roomId, dto) {
    await HttpAuth.put(`/rooms/${roomId}/permission`, dto);
  },
  getWhiteListIpFromRoom(roomId) {
    return HttpAuth.get(`/admin/rooms/${roomId}/ip`).then(({ json }) => ({
      data: json["items"].map((item, index) => {
        return {
          ...item,
          id: index,
          createdAt:
            item.createdAt instanceof Date
              ? item.createdAt
              : new Date(item.createdAt).toLocaleString(),
        };
      }),
    }));
  },
  createIpInWhiteList(data, roomId) {
    return HttpAuth.post(`/admin/rooms/${roomId}/ip`, data);
  },
  updateIpInWhiteList(data, roomId, ip) {
    return HttpAuth.put(`/admin/rooms/${roomId}/ip/${ip}`, data);
  },
  deleteIpInWhiteList(roomId, ip) {
    return HttpAuth.delete(`/admin/rooms/${roomId}/ip/${ip}`);
  },

  /* Room settings */
  getRoomSetting(roomId, key) {
    return HttpAuth.get(`/admin/rooms/${roomId}/settings/${key}`).then(
      ({ json }) => ({
        data: json,
      })
    );
  },
  changeRoomSetting(roomId, key, data) {
    return HttpAuth.put(`/admin/rooms/${roomId}/settings/${key}`, data);
  },
  /* Room reassign owner */
  getRoomInvalidOwner(from, to) {
    return HttpAuth.get(
      `/admin/rooms/list-invalid-owner?from=${from}&to=${to}`
    ).then(({ json }) => ({
      data: json,
    }));
  },
  getListMember(roomId, from, to, ignoreDeactiveAndBot = false) {
    const path = `/admin/rooms/${roomId}/list-members?from=${from}&to=${to}&ignoreDeactiveAndBot=${ignoreDeactiveAndBot}`;
    return HttpAuth.get(path).then(({ json }) => ({
      data: json,
    }));
  },
  // data: {roomId: string, userId: string | null}
  assignOwnerRoom(data) {
    return HttpAuth.post("/admin/rooms/re-assign-owner", data);
  },
  // data: {roomId: string, ownerId: string, targetUserId: string | null}
  transferOwner(data) {
    return HttpAuth.post("/admin/rooms/transfer-owner", data);
  },
  /* Room permission */
  updatePermissions(roomId, data) {
    return HttpAuth.put(`/admin/rooms/${roomId}/permissions`, data);
  },
  updateUserRoleInRoom(roomId, data) {
    return HttpAuth.put(`/admin/rooms/${roomId}/member-role`, data);
  },
  /* Room auto delete messages */
  setAutoDeleteMessages(roomId, data) {
    return HttpAuth.put(`/admin/rooms/${roomId}/retention`, data);
  },
  clearRoomStateName(roomId) {
    return HttpAuth.put(`/admin/rooms/${roomId}/state/m.room.name/`, {
      name: "",
    });
  },
  setRoomEncryption(roomId, data) {
    return HttpAuth.put(
      `/admin/rooms/${roomId}/state/m.room.encryption/`,
      data
    );
  },
};

export default roomProvider;
