import React, { useEffect, useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import { Button } from "@mui/material";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormControl from "@material-ui/core/FormControl";
import { MenuItem, Select } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import settingProvider from "../../../synapse/settingProvider";
import { isValidIP } from "../../../helper/utils";
import PropTypes from "prop-types";

const initValidations = {
  ip: "",
  note: "",
};

const featureOptions = ["Login"];

const showFeatureField = false;

const typeOptions = ["Whitelist", "Blacklist"];

const IpAddressDialog = ({ data, onClose, onSaveSuccess }) => {
  const notify = useNotify();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [validations, setValidations] = useState(initValidations);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (data?.ip) {
      setForm({ ...data });
    } else {
      setForm({
        feature: featureOptions[0],
        type: typeOptions[0].toLowerCase(),
      });
    }
  }, [data]);

  const handleMessageErr = (value, field) => {
    if (!value?.length && field === "ip") {
      return `resources.room_whitelist.validation.required_ip`;
    }
    if (/^\s*$/.test(value?.trim()) && field === "ip") {
      return `resources.room_whitelist.validation.invalid_ip`;
    }
    if (value?.length > 255 && field === "ip") {
      return `resources.room_whitelist.validation.invalid_length`;
    }
    if (!isValidIP(value) && field === "ip") {
      return `resources.room_whitelist.validation.invalid_ip_format`;
    }
    if (value?.length > 255 && field === "note") {
      return `resources.bots.validations.invalid_length_255`;
    }
    return "";
  };

  const onFormChange = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    setValidations({
      ...validations,
      [field]: handleMessageErr(value, field),
    });
  };

  const onSaveIpAddress = () => {
    setLoading(true);
    const method = data.ip ? settingProvider.updateIPAddress : settingProvider.addIPAddress;
    method
      .bind(settingProvider)(form)
      .then(() => {
        onSaveSuccess();
      })
      .catch(e => {
        notify(e.body?.error || e.message, { type: "error" });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onCloseDialog = () => {
    setValidations(initValidations);
    onClose();
  };

  return (
    <StyledDialog onClose={onCloseDialog} aria-labelledby="reset-dialog-title" open={data.isOpen}>
      <DialogTitle>{data.ip ? `Update ${data.ip}` : "Add IP address"}</DialogTitle>
      <DialogContent>
        {showFeatureField && (
          <FormControl
            variant="outlined"
            style={{ width: "100%", marginTop: "10px", padding: "5px 0 5px" }}
            required={true}
            size="small"
          >
            <InputLabel id="ip-address-feature">{translate("resources.settings.fields.ip_feature")}</InputLabel>
            <Select
              labelId="ip-address-feature-label"
              id="ip-address-feature"
              variant="outlined"
              value={form.feature || featureOptions[0]}
              label="resources.settings.fields.ip_feature"
              disabled={loading}
              onChange={e => onFormChange("feature", e.target.value)}
            >
              {featureOptions.map(feature => (
                <MenuItem key={feature} value={feature}>
                  {feature}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl
          variant="outlined"
          style={{ width: "100%", marginTop: "10px" }}
          error={validations.ip}
          required={true}
          size="small"
          disabled={data?.ip}
        >
          <InputLabel htmlFor="component-outlined">{translate("resources.settings.fields.ip_address")}</InputLabel>
          <StyledOutlinedInput
            value={form.ip}
            id="ip"
            onChange={e => onFormChange("ip", e.target.value)}
            onClick={e => onFormChange("ip", e.target.value)}
            label={translate("resources.settings.fields.ip_address")}
          />
          <FormHelperText id="ip">{validations?.ip ? translate(validations.ip) : ""}</FormHelperText>
        </FormControl>
        <FormControl variant="outlined" style={{ width: "100%", marginTop: "10px" }} required={true} size="small">
          <InputLabel id="ip-address-type">{translate("resources.settings.fields.ip_type")}</InputLabel>
          <Select
            labelId="ip-address-type-label"
            id="ip-address-type"
            variant="outlined"
            value={form.type || typeOptions[0]}
            label={translate("resources.settings.fields.ip_type")}
            disabled={loading}
            onChange={e => onFormChange("type", e.target.value)}
          >
            {typeOptions.map(type => (
              <MenuItem key={type} value={type.toLowerCase()}>
                {translate(`resources.settings.labels.list_ip_type_${type.toLowerCase()}`)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant="outlined"
          style={{ width: "100%", marginTop: "10px", padding: "5px 0 5px" }}
          error={validations.note}
          required={false}
        >
          <InputLabel htmlFor="component-outlined">{translate("resources.settings.fields.ip_note")}</InputLabel>
          <OutlinedInput
            id="note"
            multiline
            minRows={3}
            maxRows={9}
            value={form.note}
            onChange={e => onFormChange("note", e.target.value)}
            onClick={e => onFormChange("note", e.target.value)}
            label={translate("resources.settings.fields.ip_note")}
          />
          <FormHelperText id="note">{validations?.note ? translate(validations.note) : ""}</FormHelperText>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button startIcon={<CloseIcon />} onClick={onCloseDialog} variant="outlined" disabled={loading}>
          {translate("resources.settings.actions.cancel")}
        </Button>
        <Button
          disabled={loading || validations.ip || validations.note || !form.ip}
          startIcon={<SaveIcon />}
          onClick={onSaveIpAddress}
          variant="contained"
        >
          {translate("resources.settings.actions.save")}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

IpAddressDialog.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onSaveSuccess: PropTypes.func,
}

const StyledDialog = withStyles({
  paperWidthSm: {
    width: "100%",
    maxWidth: "50vw",
  },
})(Dialog);

const StyledOutlinedInput = withStyles({
  input: {
    padding: "12px 18px 12px 14px",
  },
})(OutlinedInput);

export default IpAddressDialog;
