import { connect } from "react-redux";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from '@material-ui/core/styles';
import { CopyToClipboard } from "react-copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import Autorenew from "@material-ui/icons/Autorenew";
import Tooltip from '@mui/material/Tooltip';
import React,
{
  useEffect,
  useState,
  useCallback
}
  from "react";
import {
  BooleanInput,
  Create,
  email,
  maxLength,
  minLength,
  Pagination,
  regex,
  required,
  SimpleForm,
  TextField,
  TextInput,
  useDataProvider,
  useTranslate,
  useNotify,
  FormDataConsumer,
  PasswordInput,
  SelectInput,
  useMutation,
  useRedirect,
} from "react-admin";
import roleProvider from "../synapse/roleProvider";
import PermissionWrapper from "./common/PermissionWrapper";
import { EPermission } from "../enum/EPermission";
import { hasPermission } from "../helper/permission";
import Box from "@mui/material/Box";
import { EUserStatus } from "../enum/EUserStatus";
import PropTypes from "prop-types";
import { randomNumber } from "../helper/utils";

export function mapStateToProps(state) {
  return {
    permissions: state.permissions.permissions,
  };
}

export const TextFieldInForm = ({ variant, ...props }) => <TextField {...props} />;
TextFieldInForm.defaultProps = TextField.defaultProps;

TextFieldInForm.propTypes = {
  variant: PropTypes.string,
}

export const useStyles = makeStyles({
  small: {
    height: "40px",
    width: "40px",
  },
  large: {
    height: "120px",
    width: "120px",
    float: "right",
  },
  boxMarginTop: {
    marginTop: '20px',
  },
  inputWidth: {
    width: '256px',
  },
  copyClipboardBox: {
    display: 'flex'
  },
  copyClipboard: {
    cursor: 'pointer',
    height: '30px',
    margin: '0 10px'
  },
  randomPassword: {
    cursor: 'pointer',
    height: '30px',
  },
  menuPaper: {
    maxHeight: '200px',
  },
  passwordActionBox: {
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
});

export const date_format = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

export const UserPagination = props => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />
);

export const AvatarField = ({ source, className, record = {} }) => (
  <Avatar src={record[source]} className={className} />
);

AvatarField.propTypes = {
  source: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  record: PropTypes.object,
}

export const PlatformOptions = [
  {
    id: "windows",
    name: "resources.users.fields.platform_windows",
  },
  {
    id: "macos",
    name: "resources.users.fields.platform_macos",
  },
  {
    id: "web",
    name: "resources.users.fields.platform_web",
  },
  {
    id: "ios",
    name: "resources.users.fields.platform_ios",
  },
  {
    id: "ipados",
    name: "resources.users.fields.platform_ipados",
  },
]

export const validatePhoneNumber = (_dataProvider, _translate) => {
  return [
    regex(/^[0-9._=\-/]+$/, "synapseadmin.users.invalid_phone_number"),
    minLength(11),
  ]
}

export const validateEmail = (dataProvider, translate) => {
  return [
    function (value, _form) {
      return !value ? 'Required' : undefined;
    },
    email(),
    checkEmailValid(dataProvider, translate),
  ]
}

export const checkEmailValid = (dataProvider, translate) => async (value, values) => {
  if(value){
    const emailParts = value.split('@');
    return !(/^[a-z0-9._=\-/]+$/.test(emailParts[0])) ? translate("resources.users.invalid_email", { smart_count: 1 }) : '';
  }
};

export const validateDisplayname = (translate) => {
  return [
    function (value) {
      return !value ? 'Required' : undefined;
    },
    checkDisplaynameValid(translate),
    maxLength(64)
  ]
}

export const backToUser = () => {
  window.location = '/#/users';
}

const checkDisplaynameValid = (translate) => async (value) => {
  if(value){
    return (/^\s*$/.test(value.trim())) ? translate("resources.users.invalid_displayname") : '';
  }
};


// eslint-disable-next-line
const validateAddress = [required(), maxLength(255)];

export function generateRandomUser() {
  const homeserver = localStorage.getItem("home_server");
  const user_id =
    "@" +
    Array(8)
      .fill("0123456789abcdefghijklmnopqrstuvwxyz")
      .map(
        x =>
          x[
            Math.floor(
              (crypto.getRandomValues(new Uint32Array(1))[0] /
                (0xffffffff + 1)) *
                x.length
            )
          ]
      )
      .join("") +
    ":" +
    homeserver;

  const password = Array(20)
    .fill(
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$"
    )
    .map(
      x =>
        x[
          Math.floor(
            (crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1)) *
              x.length
          )
        ]
    )
    .join("");

  return {
    id: user_id,
    password: password,
  };
}

export const VerticalLine = (props) => {
  return (
    <div
      style={
        {
          borderLeft: '1px solid #ccc',
          margin: '0 5px 0 5px',
          paddingLeft: '5px',
          height: '35px',
        }
      }
    >
      { props?.children && React.cloneElement(props.children, { ...props }) }
    </div>
  );
};

VerticalLine.propTypes = {
  children: PropTypes.node,
}

export const VerticalLineButton = (props) => {
  return (
      <div
          style={
            {
              borderLeft: '1px solid #ccc',
              margin: '0 5px 0 5px',
              paddingLeft: '5px',
              height: '35px',
            }
          }
      >
        { props.children }
      </div>
  );
};

VerticalLineButton.propTypes = {
  children: PropTypes.node,
}

export const FieldLabel = ({ children }) => {
  return (
    <div
      style={{
        color: 'rgba(0, 0, 0, 0.54)',
        padding: '0',
        fontSize: '12px',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        marginTop: '0px',
      }}
    >
      { children }
    </div>
  );
}

FieldLabel.propTypes = {
  children: PropTypes.node,
}

export const TextFieldCs = (props) => {
  const status = props.status;
  let color;
  switch (status) {
    case EUserStatus.ACTIVATED:
      color = '#2cbf52';
      break;
    case EUserStatus.DELETED:
    case EUserStatus.DEACTIVATED:
      color = 'rgb(244, 67, 54)';
      break;
    case EUserStatus.ACTIVATING:
    case EUserStatus.DEACTIVATING:
    case EUserStatus.DELETING:
      color = '#ffaf2b';
      break;
    default:
      color = '#000000';
      break;
  }
  return (
    <div
      style={{
        fontSize: '1rem',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight: '1.5',
        letterSpacing: '0.00938em',
        padding: '8px 0 4px',
        color: color,
      }}
    >
      { props.children }
    </div>
  );
}

TextFieldCs.propTypes = {
  status: PropTypes.string,
  children: PropTypes.node,
}

export const DELETE_MODAL_TEMPLATE = {
  CHECKING_DATA: "CHECKING_DATA",
  DO_NOT_HAVE_PERMISSION: "DO_NOT_HAVE_PERMISSION",
}

export const DeleteModalTemplate = props => {
  const {translate, templateName} = props;
  let content;
  switch (templateName) {
    case DELETE_MODAL_TEMPLATE.CHECKING_DATA:
      content = translate("assigning.checking_room");
      break;
    case DELETE_MODAL_TEMPLATE.DO_NOT_HAVE_PERMISSION:
      content = (
          <Box
            sx={{
              color: "red",
            }}
          >
            {translate("assigning.dont_permission_delete")}
          </Box>
        );
      break;
    default:
      content = <></>;
      break;
  }
  return (
    <Box
      sx={{
        textAlign: "center",
      }}
    >
      {content}
    </Box>
  );
}

DeleteModalTemplate.propTypes = {
  translate: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
}

export const passwordValidation = (value, _) =>  {
  if ((/^\s*$/.test(value?.trim())) || (/\s/.test(value))) {
      return 'resources.users.invalid_password';
  }
  return undefined;
}

export const UserCreateConnect = props => {
  const notify = useNotify();
  const [mutate] = useMutation();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const classes = useStyles();

  const [isFetchRoles, setIsFetchRoles] = useState(false);
  const [listRoles, setListRoles] = useState([]);
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('Copied');

  useEffect(() => {
    // Fetch list roles for Autocomplete input
    if (hasPermission([EPermission.SUPER_ADMIN], props.permissions)) {
      setIsFetchRoles(true);
      roleProvider.getListRoles().then((roles) => {
        setListRoles(roles);
        setIsFetchRoles(false);
      }).catch(error => {
        setIsFetchRoles(false);
      });
    }
  }, [setIsFetchRoles, setListRoles, props.permissions]);

  function onRandomPassword(event, formData)  {
    event.preventDefault();
    formData.password = randomNumber().toString(36).slice(-8);
    document.getElementById("password").focus();
  }

  const onCopy = (event, formData) => {
    event.preventDefault();
    setCopyText(formData.password ? translate("resources.users.action.copied") : translate("resources.users.action.empty"));
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000)
  }

  const save = useCallback(
    async (values) => {
      try {
        await mutate({
          type: 'create',
          resource: 'users',
          payload: { data: values },
        }, {
          returnPromise: true,
          onSuccess: (data) => {
            redirect('edit', '/users', data['data']['id']);
            notify("resources.users.create_success", { type: "success" });
          }
        });
      } catch (e) {
        if (e.body?.statusCode === 400 && e.body?.errors) {
          notify("resources.users.invalid_form", { type: "error" });
          return e.body.errors;
        } else {
          notify("resources.users.save_error", { type: "error" });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutate, redirect]
  );

  return (
    <Create {...props}>
      <SimpleForm save={save}>
        {/*<TextInput source="id" autoComplete="off" validate={validateUser} />*/}
        <TextInput
          source="email"
          autoComplete="off"
          variant="outlined"
          validate={validateEmail(dataProvider, translate)}
        />
        <TextInput
          source="phonenumber"
          autoComplete="off"
          label="resources.users.fields.phoneNumber"
          helperText={false}
          variant="outlined"
          validate={validatePhoneNumber(dataProvider, translate)}
        />
        <TextField
          source={translate(
            "resources.users.helper.phone_include_country_code",
            {
              phoneNumber: '84949196969'
            }
          )}
        />
        <TextInput
          source="displayname"
          label="resources.users.fields.displayname"
          variant="outlined"
          validate={validateDisplayname(translate)}
        />
        {/*<PasswordInput
          source="password"
          variant="outlined"
          autoComplete="new-password"
          validate={maxLength(512)}
        />*/}
        <PermissionWrapper names={[ EPermission.SUPER_ADMIN ]}>
          <BooleanInput source="admin" label="resources.users.fields.sub_admin"/>
          <FormDataConsumer>
            {
              ({ formData }) => formData.admin && (
                <>
                  <div className={classes.copyClipboardBox}>
                    <PasswordInput
                      id="password"
                      className={classes.inputWidth}
                      source="password"
                      autoComplete="new-password"
                      variant="outlined"
                      validate={[
                        passwordValidation,
                        minLength(6, "resources.profile.fields.minlength_password")
                      ]}
                    />
                    <div className={classes.passwordActionBox}>
                      <CopyToClipboard text={formData.password}>
                        <span
                          className={classes.copyClipboard}
                          onClick={(event) => onCopy(event, formData)}
                          onKeyDown={() => {}}
                          tabIndex="0"
                          role="button"
                        >
                          <Tooltip title={copyText} open={isCopied} placement="top">
                            <FileCopyIcon style={{fontSize: "23"}}/>
                          </Tooltip>
                        </span>
                      </CopyToClipboard>
                      <span
                        className={classes.randomPassword}
                        onClick={(event) => onRandomPassword(event, formData)}
                        onKeyDown={() => {}}
                        tabIndex="0"
                        role="button"
                      >
                        <Tooltip title={translate("resources.users.action.generate")} placement="top">
                          <Autorenew style={{fontSize: "25"}}/>
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                  <br/>
                  <SelectInput
                    variant="outlined"
                    source="role_id"
                    choices={listRoles}
                    optionText="name"
                    optionValue="roleId"
                    size="medium"
                    translateChoice={false}
                    className={classes.inputWidth}
                    loading={isFetchRoles}
                    label="resources.users.fields.role"
                    resettable={true}
                  />
                </>
              )
            }
          </FormDataConsumer>
        </PermissionWrapper>
      </SimpleForm>
    </Create>
  )
};

UserCreateConnect.propTypes = {
  permissions: PropTypes.array,
}

export const UserCreate = connect(mapStateToProps)(UserCreateConnect);
