import { isIP } from 'class-validator';

export function isProduction() {
  return process.env.NODE_ENV?.toLowerCase() === "production";
}

export const formatDate = (date) => {
  const newData = new Date(date);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return newData.toLocaleDateString("en-US", options);
};

/**
 * Shorten string.
 * @param {string} str input string
 * @param {number} max characters
 * @param {string} sep optional customize the separator
 *
 * @returns {string}
 *
 * @example
 * console.log( truncate("123456789abcde") ); // 123...bcde (using built-in defaults)
 * console.log( truncate("123456789abcde", 8) ); // 12...cde (max of 8 characters)
 * console.log( truncate("123456789abcde", 12, "_") ); // 12345_9abcde (customize the separator)
 */
export function truncate(str, max, sep = '...') {
  // Default to 10 characters
  max = max || 10;

  let len = str.length;
  if (len > max) {
    // Default to ellipsis
    sep = sep || "...";

    let sepLength = sep.length;

    // If separator is larger than character limit,
    // well then we don't want to just show the separator,
    // so just show right hand side of the string.
    if (sepLength > max) {
      return str.slice(len - max);
    }

    // Half the difference between max and string length.
    // Multiply negative because small minus big.
    // Must account for length of separator too.
    let n = -0.5 * (max - len - sepLength);

    // This gives us the center line.
    let center = len / 2;

    let front = str.slice(0, center - n);
    let back = str.slice(len - center + n); // without second arg, will automatically go to end of line.

    return front + sep + back;
  }

  return str;
}

/**
 * Check valid file type.
 * @param {string} fileExtension file extension
 * @param {Array.<string>} allowedExtension list allow extension
 *
 * @returns {boolean}
 *
 * @example
 * console.log( validateFileType('image/jpg', ["png", "jpg", "jpeg"]) ); // true
 */
export function validateFileType(fileExtension, allowedExtension) {
  let isValid = false;

  for (let index in allowedExtension) {
    if (fileExtension.includes(allowedExtension[index])) {
      isValid = true;
      break;
    }
  }

  return isValid;
}

/**
 * Check inValid format ip address.
 * @param {string} ipAddress file extension
 *
 * @returns {boolean}
 */
export function isValidIP(ipAddress) {
  if (!ipAddress) return false;
  return isIP(ipAddress);
}

export function isMobile() {
  const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
  const userAgent = navigator.userAgent.toLowerCase();
  return mobileRegex.test(userAgent);
}

export function sortAlphabetASC(arrayInput) {
  return arrayInput.sort((a, b) => {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
  });
}

export function sortKeys(unordered) {
  return Object.keys(unordered)
      .sort((a, b) => a - b)
      .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
      }, {});
}

export function randomNumber() {
  // alternative for Math.random()
  const crypto = window.crypto || window.msCrypto;
  return crypto.getRandomValues(new Uint32Array(1))[0] / 2**32; // /2^32
}

export function randomString(len, charSet) {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  for (let i = 0; i < len; i++) {
    let randomPoz = Math.floor(randomNumber() * charSet.length);
      randomString += charSet.substring(randomPoz,randomPoz+1);
  }
  return randomString;
}

export const getTimeByDays = (days) => {
  return days * 24 * 60 * 60 * 1000;
};

export const getDaysByTime = (time) => {
  return time / 24 / 60 / 60 / 1000;
};

export const getTimeByMinutes = (minutes) => {
  return minutes * 60 * 1000;
};

export const getMinutesByTime = (time) => {
  return time / 60 / 1000;
};

export const getTimeByHours = (hours) => {
  return hours * 60 * 60 * 1000;
};

export const getHoursByTime = (time) => {
  return time / 60 / 60 / 1000;
};

export const getHoursBySeconds = (second) => {
  return second / 60 / 60;
};

export const getSecondsByHours = (hours) => {
  return hours * 60 * 60;
};

export const bytesToMegabytes = (bytes) => {
  console.log(bytes / (1024 * 1024))
  return bytes / (1024 * 1024);
};

export const megabytesToBytes = (megabytes) => {
  return megabytes * (1024 * 1024);
};

export const transformStringify = (originalString) => {
  return originalString?.replace(/(["\\])/g, '\\$1');
};

export const stringToArray = (text) => {
  return text?.split(',').map(item => item.trim());
};

export const validateArrayValuesInEnum = (valuesArray, enumObject) => {
  const validValues = Object.values(enumObject);
  return valuesArray.every(value => validValues.includes(value));
}

export const removeLeadingZero = (valueInput) => {
  if (typeof valueInput !== 'string') return valueInput;
  return valueInput?.replace(/^0+(?!$)/, "");
}
