import { ThemeProvider } from "@mui/material";
import muiTheme from "../../helper/muiTheme";
import React, { useState, useEffect } from "react";
import { useTranslate, useNotify, Loading, Button } from "react-admin";
import Box from "@mui/material/Box";
import ButtonMui from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import CancelIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/CheckCircle";
import { withStyles } from "@material-ui/core/styles";
import { DataGrid, GridToolbarContainer, GridActionsCellItem, GridToolbarQuickFilter } from "@mui/x-data-grid";
import roomProvider from "../../synapse/roomProvider";
import { getCurrentUserId } from "../../utils/storage";
import { logger } from "../../utils/logger";
import { Pagination } from "../common/Pagination";
import { isValidIP } from "../../helper/utils";
import PropTypes from "prop-types";

const SETTING_WHITED_IPS_KEY = "allow_config_wl_ip";

const initValidations = {
  ip: {
    isValid: false,
    messageErr: "",
  },
  name: {
    isValid: false,
    messageErr: "",
  },
};

const EditToolbar = props => {
  const translate = useTranslate();
  const { setIsOpenDialog, setIsCreate, setRecordEdit, whitelistedIpSetting, loading, onHandleChangeConfig } = props;
  const handleClick = () => {
    setIsOpenDialog(true);
    setRecordEdit({
      ip: "",
      name: "",
    });
    setIsCreate(true);
  };

  return (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        flexFlow: "column",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <FormControlLabel
          control={
            loading ? (
              <StyledCircularProgress />
            ) : (
              <Switch
                checked={whitelistedIpSetting.inRoom}
                onChange={onHandleChangeConfig}
                name="allow_config_wl_ip"
                color="primary"
                disabled={!whitelistedIpSetting.global}
              />
            )
          }
          label={translate("resources.room_whitelist.actions.enable_whitelisted_ips")}
        />
        <GridToolbarQuickFilter
          quickFilterParser={searchInput => searchInput.split(",").map(value => value)}
          quickFilterFormatter={quickFilterValues => quickFilterValues.join(", ")}
          debounceMs={500} // time before applying the new quick filter value
        />
      </div>
      <ButtonMui color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </ButtonMui>
    </GridToolbarContainer>
  );
};

EditToolbar.propTypes = {
  setIsOpenDialog: PropTypes.func.isRequired,
  setIsCreate: PropTypes.func.isRequired,
  setRecordEdit: PropTypes.func.isRequired,
  whitelistedIpSetting: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  onHandleChangeConfig: PropTypes.func.isRequired,
}

export const RoomWhiteListIp = ({ ...props }) => {
  const translate = useTranslate();
  const roomId = props.id;
  const currentUserId = getCurrentUserId();
  const notify = useNotify();
  const [loadingPage, setLoadingPage] = useState(true);
  const [loading, setLoading] = useState(false);
  // Fetch data
  const [rows, setRows] = useState([]);
  const [whitelistedIpSetting, setWhitelistedIpSetting] = useState({});

  // CreateUpdate
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [validations, setValidations] = useState(initValidations);
  const [recordEdit, setRecordEdit] = useState({});

  // Confirm Delete
  const [isOpenDialogConfirm, setIsOpenDialogConfirm] = useState(false);
  const [recordDelete, setRecordDelete] = useState({});

  const titleDialogTranslate = isCreate ? "create" : "update";

  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadData = async () => {
    const whitelistIps = roomProvider.getWhiteListIpFromRoom(roomId);
    const whitelistSetting = roomProvider.getRoomSetting(roomId, SETTING_WHITED_IPS_KEY);
    Promise.all([whitelistIps, whitelistSetting])
      .then(results => {
        setRows(results[0]?.data);
        setWhitelistedIpSetting(results[1]?.data.value);
      })
      .catch(error => {
        notify(error.message, { type: "error" });
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  const handleEditClick = (id, row) => () => {
    setRecordEdit(row);
    setIsOpenDialog(true);
  };

  const handleDeleteClick = (id, row) => () => {
    setIsOpenDialogConfirm(true);
    setRecordDelete(row);
  };

  const columns = [
    {
      field: "name",
      headerName: translate("resources.room_whitelist.fields.name"),
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      whiteSpace: "unset",
    },
    {
      field: "ip",
      headerName: translate("resources.room_whitelist.fields.ip"),
      width: 330,
      align: "left",
      headerAlign: "left",
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: translate("resources.room_whitelist.fields.createdAt"),
      // type: 'date',
      width: 220,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdBy",
      headerName: translate("resources.room_whitelist.fields.createdBy"),
      width: 250,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: translate("resources.room_whitelist.fields.actions"),
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={"edit_" + rows[id]}
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id, rows[id])}
            color="inherit"
          />,
          <GridActionsCellItem
            key={"delete_" + rows[id]}
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id, rows[id])}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const onCloseDialog = () => {
    setIsOpenDialog(false);
    setTimeout(() => {
      setIsCreate(false);
    }, 500);
    setValidations(initValidations);
    setRecordEdit({});
  };

  const onCloseDialogConfirm = () => {
    setIsOpenDialogConfirm(false);
    setRecordDelete({});
  };

  const checkIsValid = (value, field) => {
    switch (field) {
      case "ip":
        return !value.length || value.length > 255 || /^\s*$/.test(value?.trim()) || !isValidIP(value);
      case "name":
        return !value.length || value.length > 64 || /^\s*$/.test(value?.trim());
      default:
        return false;
    }
  };

  const handleMessageErr = (value, field) => {
    if (!value.length && field === "ip") {
      return `resources.room_whitelist.validation.required_ip`;
    }
    if (/^\s*$/.test(value?.trim()) && field === "ip") {
      return `resources.room_whitelist.validation.invalid_ip`;
    }
    if (value.length > 255 && field === "ip") {
      return `resources.room_whitelist.validation.invalid_length`;
    }
    if (!isValidIP(value) && field === "ip") {
      return `resources.room_whitelist.validation.invalid_ip_format`;
    }
    if (value.length > 64 && field === "name") {
      return `resources.room_whitelist.validation.invalid_length_64`;
    }
    if (!value.length && field === "name") {
      return `resources.room_whitelist.validation.required_name`;
    }
    if (/^\s*$/.test(value?.trim()) && field === "name") {
      return `resources.room_whitelist.validation.invalid_name`;
    }
  };

  const onValueChange = (value, field) => {
    const valueInput = value;
    setRecordEdit({
      ...recordEdit,
      [field]: valueInput,
    });
    setValidations({
      ...validations,
      [field]: {
        isValid: checkIsValid(valueInput, field),
        messageErr: handleMessageErr(valueInput, field),
      },
    });
  };

  const onSaveData = () => {
    setLoading(true);
    if (isCreate) {
      const newRecord = {
        ip: recordEdit.ip.trim(),
        name: recordEdit.name?.trim(),
        createdAt: new Date(),
        createdBy: currentUserId,
        roomId: roomId,
      };
      roomProvider
        .createIpInWhiteList(newRecord, roomId)
        .then(() => {
          notify(`resources.room_whitelist.validation.save_sucess`);
          onCloseDialog();
          reloadData();
        })
        .catch(error => {
          logger.error(error.message);
          if (error.body?.errcode === "M_RECORD_ALREADY_EXISTS") {
            setValidations({
              ...validations,
              ip: {
                isValid: true,
                messageErr: "resources.room_whitelist.validation.existed_ip",
              },
            });
            notify("resources.room_whitelist.validation.invalid_form", {
              type: "error",
            });
          } else {
            notify(`resources.room_whitelist.validation.save_failed`, {
              type: "error",
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      const record = {
        ...recordEdit,
        name: recordEdit.name?.trim(),
      };
      roomProvider
        .updateIpInWhiteList(record, record.roomId, record.ip)
        .then(() => {
          notify(`resources.room_whitelist.validation.save_sucess`);
          onCloseDialog();
          reloadData();
        })
        .catch(error => {
          logger.error(error.message);
          notify(`resources.room_whitelist.validation.save_failed`, {
            type: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onDeleteRecord = () => {
    setLoading(true);
    roomProvider
      .deleteIpInWhiteList(recordDelete.roomId, recordDelete.ip)
      .then(() => {
        notify(`resources.room_whitelist.validation.delete_sucess`);
        onCloseDialogConfirm();
        reloadData();
      })
      .catch(error => {
        logger.error(error.message);
        notify(`resources.room_whitelist.validation.delete_failed`, {
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onHandleChangeConfig = async event => {
    setLoading(true);
    const selectedValue = event.target.checked;
    roomProvider
      .changeRoomSetting(roomId, SETTING_WHITED_IPS_KEY, {
        value: selectedValue,
      })
      .then(() => {
        setWhitelistedIpSetting({
          ...whitelistedIpSetting,
          inRoom: selectedValue,
        });
        notify("resources.room_whitelist.notifications.change_success", {
          type: "success",
        });
      })
      .catch(e => {
        if (e.body?.errcode === "M_CANNOT_CHANGE_CONFIG_WHITELISTED_IP") {
          roomProvider.getRoomSetting(roomId, SETTING_WHITED_IPS_KEY).then(res => {
            setWhitelistedIpSetting(res?.data.value);
          });
        }
        notify("resources.room_whitelist.notifications.change_failed", {
          type: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onRowsPerPageChange = event => {
    if (event.pageSize) {
      setPageSize(event.pageSize);
    }
  };

  if (loadingPage) return <Loading />;
  return (
    <ThemeProvider theme={muiTheme}>
      <Box
        sx={{
          height: "70vh",
          width: "100%",
          "& .actions": {
            color: "text.secondary",
          },
          "& .textPrimary": {
            color: "text.primary",
          },
          marginBottom: "10px",
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          isRowSelectable={() => false}
          initialState={{
            pagination: { paginationModel: { pageSize: pageSize } },
          }}
          pageSizeOptions={[10, 25, 50]}
          onPaginationModelChange={event => onRowsPerPageChange(event)}
          slots={{
            pagination: Pagination,
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: {
              setIsOpenDialog,
              setIsCreate,
              setRecordEdit,
              whitelistedIpSetting,
              loading,
              onHandleChangeConfig,
            },
          }}
          sx={{
            border: "none",
            "& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell": {
              whiteSpace: "unset",
            },
          }}
        />
      </Box>
      {/* Dialog Create Update */}
      {
        <Dialog onClose={onCloseDialog} aria-labelledby="reset-dialog-title" open={isOpenDialog}>
          <DialogTitle id="reset-dialog-title">
            {translate(`resources.room_whitelist.actions.title_${titleDialogTranslate}`)}
          </DialogTitle>
          <DialogContent>
            <FormControl
              variant="outlined"
              style={{ width: "100%", marginTop: "10px", padding: "5px 0 5px" }}
              error={validations.name.isValid}
              required={true}
            >
              <InputLabel htmlFor="component-outlined">{translate("resources.room_whitelist.fields.name")}</InputLabel>
              <StyledOutlinedInput
                id="name"
                value={recordEdit.name}
                onChange={e => onValueChange(e.target.value, e.target.id)}
                onClick={e => onValueChange(e.target.value, e.target.id)}
                label="Name"
              />
              <FormHelperText id="name">
                {validations?.name?.messageErr ? translate(validations?.name?.messageErr) : ""}
              </FormHelperText>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ width: "100%", marginTop: "10px", padding: "5px 0 5px" }}
              error={validations.ip.isValid}
              required={true}
            >
              <InputLabel htmlFor="component-outlined">{translate("resources.room_whitelist.fields.ip")}</InputLabel>
              <StyledOutlinedInput
                id="ip"
                value={recordEdit.ip}
                onChange={e => onValueChange(e.target.value, e.target.id)}
                onClick={e => onValueChange(e.target.value, e.target.id)}
                label="IP"
                disabled={!isCreate}
              />
              <FormHelperText id="ip">
                {validations?.ip?.messageErr ? translate(validations?.ip?.messageErr) : ""}
              </FormHelperText>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseDialog} color="inherit" label="Cancel">
              <CancelIcon />
            </Button>
            {loading ? (
              <CircularProgress style={{ height: "20px", width: "20px" }} color="primary" />
            ) : (
              <Button
                onClick={onSaveData}
                color="primary"
                label="Confirm"
                disabled={validations.ip.isValid || validations.name.isValid || !recordEdit.ip || !recordEdit.name}
              >
                <CheckIcon />
              </Button>
            )}
          </DialogActions>
        </Dialog>
      }
      {/* Dialog Confirm Delete */}
      {
        <Dialog onClose={onCloseDialogConfirm} aria-labelledby="reset-dialog-title" open={isOpenDialogConfirm}>
          <DialogTitle id="reset-dialog-title">
            {translate("resources.room_whitelist.actions.title_delete")} {recordDelete.ip}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="reset-dialog-description">
              {translate("resources.room_whitelist.actions.content_confirm_delete")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCloseDialogConfirm} color="inherit" label="Cancel">
              <CancelIcon />
            </Button>
            {loading ? (
              <CircularProgress style={{ height: "20px", width: "20px" }} color="primary" />
            ) : (
              <Button onClick={onDeleteRecord} color="primary" label="Confirm">
                <CheckIcon />
              </Button>
            )}
          </DialogActions>
        </Dialog>
      }
    </ThemeProvider>
  );
};

RoomWhiteListIp.propTypes = {
  id: PropTypes.string,
}

const StyledOutlinedInput = withStyles({
  input: {
    padding: "12px 18px 12px 14px",
  },
})(OutlinedInput);

const StyledCircularProgress = withStyles({
  root: {
    width: "30px !important",
    height: "30px !important",
    padding: "5px",
  },
})(CircularProgress);
