import React, { Fragment, useState, useEffect, cloneElement } from "react";
import { connect } from "react-redux";
import {
  BooleanField,
  BulkDeleteButton,
  Datagrid,
  DeleteButton,
  Filter,
  List,
  Pagination,
  SearchInput,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useRecordContext,
  useTranslate,
  useNotify,
  sanitizeListRestProps,
  Loading,
} from "react-admin";
import get from "lodash/get";
import PropTypes from "prop-types";
// import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography, Chip } from "@material-ui/core";
import HttpsIcon from "@material-ui/icons/Https";
import NoEncryptionIcon from "@material-ui/icons/NoEncryption";
import PageviewIcon from "@material-ui/icons/Pageview";
import UserIcon from "@material-ui/icons/Group";
import VisibilityIcon from "@material-ui/icons/Visibility";
// import Switch from '@material-ui/core/Switch';
// import { withStyles } from '@material-ui/core/styles';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import CircularProgress from '@material-ui/core/CircularProgress';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import SecurityIcon from '@material-ui/icons/Security';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Button from '@material-ui/core/Button';
import { Box, FormLabel, ThemeProvider, Button as ButtonMui } from "@mui/material";
import {
  RoomDirectoryBulkDeleteButton,
  RoomDirectoryDeleteButton,
} from "./RoomDirectory";
import dataProvider from "../synapse/dataProvider";
import { EPermission } from "../enum/EPermission";
import { hasPermission } from "../helper/permission";
import PermissionWrapper from "./common/PermissionWrapper";
import ExportButton from "./common/ExportButton";
import { RoomWhiteListIp } from "./rooms/RoomWhiteListIp";
import { RoomOwner } from "./room-owner-management/RoomOwner";
import { ROOM_OWNER_TYPE } from "../enum/ERoomOwner";
import { RoomPermissions } from "./rooms/RoomPermissions";
import muiTheme from "../helper/muiTheme";
import { RoomMembers } from "./rooms/RoomMembers";
import { RoomSecurity } from "./rooms/RoomSecurity";
import { RoomEncryption } from "./rooms/RoomEncryption";
import roomProvider from "../synapse/roomProvider";
/*
const date_format = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const useStyles = makeStyles(theme => ({
  helper_forward_extremities: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    margin: "0.5em",
  },
}));
 */

const backToListRoom = () => {
  window.location = '/#/rooms';
}

function mapStateToProps(state) {
  return {
    roomFilters: state.admin.resources.rooms.list.params.displayedFilters,
    permissions: state.permissions.permissions,
  };
}

const getRoomStateByKey = (data, key) => {
  return data.find(
    (e) => e.type === key
  )?.content;
}

const RoomPagination = props => (
  <Pagination {...props} rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />
);

const EncryptionField = ({ source, record = {}, emptyText }) => {
  const translate = useTranslate();
  const value = get(record, source);
  let ariaLabel = value === false ? "ra.boolean.false" : "ra.boolean.true";

  if (value === false || value === true) {
    return (
      <Typography component="span" variant="body2">
        <Tooltip title={translate(ariaLabel, { _: ariaLabel })}>
          {value === true ? (
            <HttpsIcon data-testid="true" htmlColor="limegreen" />
          ) : (
            <NoEncryptionIcon data-testid="false" color="error" />
          )}
        </Tooltip>
      </Typography>
    );
  }

  return (
    <Typography component="span" variant="body2">
      {emptyText}
    </Typography>
  );
};

const RoomTitle = ({ record }) => {
  const translate = useTranslate();
  let name = "";
  if (record) {
    name = record.name !== "" ? record.name : record.id;
  }

  return (
    <span>
      {translate("resources.rooms.name", 1)} {name}
    </span>
  );
};

const RoomShowActions = ({ basePath, data, resource }) => {
  let roomDirectoryStatus = "";
  if (data) {
    roomDirectoryStatus = data.public;
  }

  return (
    <TopToolbar>
      {/* {roomDirectoryStatus === false && (
        <RoomDirectorySaveButton record={data} />
      )} */}
      {roomDirectoryStatus === true && (
        <RoomDirectoryDeleteButton record={data} />
      )}
      {
        !data?.isServerNoticeRoom && <PermissionWrapper names={[ EPermission.SUPER_ADMIN ]}>
          <DeleteButton
            basePath={basePath}
            record={data}
            resource={resource}
            mutationMode="pessimistic"
            confirmTitle="resources.rooms.action.erase.title"
            confirmContent="resources.rooms.action.erase.content"
          />
        </PermissionWrapper>
      }
    </TopToolbar>
  );
};

export const RoomShowConnect = props => {
  const translate = useTranslate();
  const notify = useNotify();
  const [roomStateName, setRoomStateName] = useState(null);
  const [roomDetails, setRoomDetails] = useState({});
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingClearBtn, setLoadingClearBtn] = useState(false);
  const [powerLevels, setPowerLevels] = useState({});
  const [autoDeleteData, setAutoDeleteData] = useState({});
  const [encryptionState, setEncryptionState] = useState({});

  const DEFAULT_PAGINATION = 10;

  useEffect(() => {
    dataProvider.getManyReference('room_state', {
      id: props.id,
      pagination: DEFAULT_PAGINATION,
      sort: '',
    }).then((result) => {
      const powerLevels = getRoomStateByKey(result.data, 'm.room.power_levels');
      const autoDelete = getRoomStateByKey(result.data, 'c.room.retention');

      setRoomStateName(getRoomStateByKey(result.data, 'm.room.name'));
      setPowerLevels(powerLevels);
      setAutoDeleteData(autoDelete);
      setEncryptionState(getRoomStateByKey(result.data, 'm.room.encryption'));
    }).catch(error => {
      notify(error.body?.error, { type: "error" });
      if (error.body?.statusCode === 404 && error.body?.errcode === 'M_NOT_FOUND') {
        backToListRoom();
      }
    }).finally(() => {
      setLoadingPage(false);
    });

    dataProvider.getOne("rooms", { id: props.id }).then((res) => {
      setRoomDetails({
        ...res?.data
      });
    }).catch(error => {
      notify(error.body?.error, { type: "error" });
    }).finally(() => {
      setLoadingPage(false);
    });
    // eslint-disable-next-line
  }, [props]);

  if (loadingPage) return <Loading />;

  const clearRoomStateName = () => {
    setLoadingClearBtn(true)
    return roomProvider.clearRoomStateName(props.id)
      .then(() => {
        notify('Clear Room state name success', { type: "success" });
        setRoomStateName(null);
      }).catch((err) => {
        notify(err.response?.data?.error || err.message, { type: "error" });
      }).finally(() => {
        setLoadingClearBtn(false);
      });
  }

  return (
    <Show {...props} actions={<RoomShowActions />} title={<RoomTitle />}>
      <ThemeProvider theme={muiTheme}>
        <TabbedShowLayout>
          {/* VT-3015 - Hide this
          <Tab label="synapseadmin.rooms.tabs.basic" icon={<ViewListIcon />}>
            <TextField source="room_id" />
            <TextField source="name" />
            <TextField source="canonical_alias" />
            <TextField source="creator" />
            <TextField source="owner" />
            // <ReferenceField source="creator" reference="users">
            //     <TextField source="id" />
            // </ReferenceField>
          </Tab>
           */}
          <Tab
            label="synapseadmin.rooms.tabs.detail"
            icon={<PageviewIcon />}
            // path="detail"
          >
            <TextField source="room_id" />
            <TextField source="name" />
            {
              roomDetails?.isDm &&
              !roomDetails?.isServerNoticeRoom &&
              roomStateName?.name && (
                <PermissionWrapper names={[ EPermission.SUPER_ADMIN ]}>
                  <Box sx={{ mb: 3 }}>
                    <FormLabel sx={{ fontSize: 12 }}>Room state name</FormLabel>
                    <Typography variant="body2" gutterBottom={true}>
                      {roomStateName?.name}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      <ButtonMui
                        disabled={loadingClearBtn}
                        variant="outlined"
                        size="small"
                        color="error"
                        onClick={clearRoomStateName}
                      >{translate("resources.settings.actions.clear")}</ButtonMui>
                    </Box>
                  </Box>
                </PermissionWrapper>
              )
            }
            <TextField source="canonical_alias" />
            <TextField source="creator" />
            <TextField source="owner" />
            <TextField source="joined_members" />
            <TextField source="joined_local_members" />
            <TextField source="joined_local_devices" />
            <TextField source="state_events" />
            <TextField source="version" />
            <TextField
              source="encryption"
              emptyText={translate("resources.rooms.enums.unencrypted")}
            />
          </Tab>
          {
            hasPermission([EPermission.ROOM_CHANGE_USER_ROLE], props.permissions) &&
            !roomDetails?.isDm &&
            !roomDetails?.isServerNoticeRoom &&
            <Tab label="synapseadmin.rooms.tabs.members" icon={<UserIcon />}>
                <RoomMembers
                  {...props}
                />
            </Tab>
          }
          {
            hasPermission([EPermission.ROOM_CHANGE_PERMISSIONS], props.permissions) &&
            !roomDetails?.isDm &&
            !roomDetails?.isServerNoticeRoom &&
            <Tab
              label="synapseadmin.rooms.tabs.permission"
              icon={<VisibilityIcon />}
              path="permission"
            >
              <BooleanField source="federatable" />
              <BooleanField source="public" />
              <SelectField
                source="join_rules"
                choices={[
                  { id: "public", name: "resources.rooms.enums.join_rules.public" },
                  { id: "knock", name: "resources.rooms.enums.join_rules.knock" },
                  { id: "invite", name: "resources.rooms.enums.join_rules.invite" },
                  {
                    id: "private",
                    name: "resources.rooms.enums.join_rules.private",
                  },
                ]}
              />
              <SelectField
                source="history_visibility"
                choices={[
                  {
                    id: "invited",
                    name: "resources.rooms.enums.history_visibility.invited",
                  },
                  {
                    id: "joined",
                    name: "resources.rooms.enums.history_visibility.joined",
                  },
                  {
                    id: "shared",
                    name: "resources.rooms.enums.history_visibility.shared",
                  },
                  {
                    id: "world_readable",
                    name: "resources.rooms.enums.history_visibility.world_readable",
                  },
                ]}
              />
              <ThemeProvider theme={muiTheme}>
                <RoomPermissions
                  {...props}
                  powerLevels={powerLevels}
                />
              </ThemeProvider>
            </Tab>
          }

          {/* VT-3015 - Hide this
          <Tab
            label={translate("resources.room_state.name", { smart_count: 2 })}
            icon={<EventIcon />}
            path="state"
          >
            <ReferenceManyField
              reference="room_state"
              target="room_id"
              addLabel={false}
            >
              <Datagrid style={{ width: "100%" }}>
                <TextField source="type" sortable={false} />
                <DateField
                  source="origin_server_ts"
                  showTime
                  options={date_format}
                  sortable={false}
                />
                <TextField source="content" sortable={false} />
                {/* <ReferenceField
                  source="sender"
                  reference="users"
                  sortable={false}
                >
                  <TextField source="id" />
                </ReferenceField> */}
          {/* VT-3015 - Hide this
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          */}
          {/* VT-3015 - Hide this
          <Tab
            label="resources.forward_extremities.name"
            icon={<FastForwardIcon />}
            path="forward_extremities"
          >
            <div className={classes.helper_forward_extremities}>
              {translate("resources.rooms.helper.forward_extremities")}
            </div>
            <ReferenceManyField
              reference="forward_extremities"
              target="room_id"
              addLabel={false}
            >
              <Datagrid style={{ width: "100%" }}>
                <TextField source="id" sortable={false} />
                <DateField
                  source="received_ts"
                  showTime
                  options={date_format}
                  sortable={false}
                />
                <NumberField source="depth" sortable={false} />
                <TextField source="state_group" sortable={false} />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          */}

          {
            hasPermission([EPermission.ROOM_CONFIG_WHITELISTED_IPS], props.permissions) &&
            !roomDetails?.isDm &&
            !roomDetails?.isServerNoticeRoom &&
            <Tab
              label={translate("resources.room_whitelist.name", { smart_count: 2 })}
              icon={<PlaylistAddCheckIcon />}
              path="whitelist"
            >
              <RoomWhiteListIp
                {...props}
              />
            </Tab>
          }

          {
            hasPermission([EPermission.ROOM_CONFIG_RETENTION], props.permissions) &&
            !roomDetails?.isServerNoticeRoom &&
            <Tab
              label={translate("resources.room_security.name", { smart_count: 2 })}
              icon={<SecurityIcon />}
              path="auto-delete-messages"
            >
              <RoomSecurity
                {...props}
                data={autoDeleteData}
              />
            </Tab>
          }

          {
            !roomDetails?.isDm &&
            !roomDetails?.isServerNoticeRoom &&
            <Tab
              label={translate("resources.room_encryption.name", { smart_count: 2 })}
              icon={<HttpsIcon />}
              path="encryption"
            >
              <RoomEncryption
                {...props}
                data={encryptionState}
              />
            </Tab>
          }
        </TabbedShowLayout>
      </ThemeProvider>
    </Show>
  );
};

export const RoomShow = connect(mapStateToProps)(RoomShowConnect);

const RoomBulkActionButtons = props => (
  <Fragment>
    {/* <RoomDirectoryBulkSaveButton {...props} /> */}
    <RoomDirectoryBulkDeleteButton {...props} />
    <BulkDeleteButton
      {...props}
      confirmTitle="resources.rooms.action.erase.title"
      confirmContent="resources.rooms.action.erase.content"
      mutationMode="pessimistic"
    />
  </Fragment>
);

const RoomFilter = ({ ...props }) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <SearchInput source="search_term" alwaysOn variant="outlined" />
      <Chip
        label={translate("resources.rooms.fields.joined_local_members")}
        source="joined_local_members"
        defaultValue={undefined}
        style={{ marginBottom: 8 }}
      />
      <Chip
        label={translate("resources.rooms.fields.state_events")}
        source="state_events"
        defaultValue={undefined}
        style={{ marginBottom: 8 }}
      />
      <Chip
        label={translate("resources.rooms.fields.version")}
        source="version"
        defaultValue={undefined}
        style={{ marginBottom: 8 }}
      />
      <Chip
        label={translate("resources.rooms.fields.federatable")}
        source="federatable"
        defaultValue={undefined}
        style={{ marginBottom: 8 }}
      />
    </Filter>
  );
};

const RoomNameField = props => {
  const { source } = props;
  const record = useRecordContext(props);
  if (!record) {
    return <span></span>;
  }

  return (
    <span>{record[source] || record["canonical_alias"] || record["id"]}</span>
  );
};

RoomNameField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

const ActionsBar = ({
  currentSort,
  className,
  resource,
  filters,
  displayedFilters,
  exporter, // you can hide ExportButton if exporter = (null || false)
  filterValues,
  permanentFilter,
  hasCreate, // you can hide CreateButton if hasCreate = false
  basePath,
  selectedIds,
  onUnselectItems,
  showFilter,
  maxResults,
  total,
  onReassignClick,
  translate,
  ...rest
}) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <PermissionWrapper names={[ EPermission.ROOM_RE_ASSIGN_OWNER ]}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={onReassignClick}
          startIcon={<AutorenewIcon />}
          style={{
            textTransform: "none",
          }}
        >
          {translate("assigning.room_title")}
        </Button>
      </PermissionWrapper>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <PermissionWrapper names={[ EPermission.ROOM_EXPORT ]}>
        <ExportButton
          filterValues={filterValues}
          currentSort={currentSort}
          total={total}
          sort={currentSort}
          filter={{ ...filterValues, ...permanentFilter }}
          {...rest}
        />
      </PermissionWrapper>
    </TopToolbar>

  )
}

const FilterableRoomList = ({ roomFilters, dispatch, permissions, ...props }) => {
  const translate = useTranslate();
  const filter = roomFilters;
  const localMembersFilter = !!(filter && filter.joined_local_members);
  const stateEventsFilter = !!(filter && filter.state_events);
  const versionFilter = !!(filter && filter.version);
  const federateableFilter = !!(filter && filter.federatable);

  const [needToShowRoomOwner, setNeedToShowRoomOwner] = React.useState(false);

  const onReassignClick = () => {
    setNeedToShowRoomOwner(true);
  }

  const onGoBackToListRoom = () => {
    setNeedToShowRoomOwner(false);
  }

  if (needToShowRoomOwner) {
    return <RoomOwner
      title={translate("assigning.room_title")}
      type={ROOM_OWNER_TYPE.ROOM}
      onGoBackClick={onGoBackToListRoom}
      {...props}
    />
  }

  return (
    <List
      {...props}
      pagination={<RoomPagination />}
      sort={{ field: "name", order: "ASC" }}
      filters={<RoomFilter />}
      bulkActionButtons={
        hasPermission(
          [
            EPermission.SUPER_ADMIN,
          ],
          permissions,
        ) && <RoomBulkActionButtons />
      }
      actions={<ActionsBar
        onReassignClick={onReassignClick}
        translate={translate}
      />}
    >
      <Datagrid
        rowClick={() => {
            if(hasPermission([EPermission.ROOM_DETAILS], permissions)) {
              return "show";
            }
          }
        }
      >
        <EncryptionField
          source="is_encrypted"
          sortBy="encryption"
          label={<HttpsIcon />}
        />
        <RoomNameField source="name" />
        <TextField source="joined_members" />
        {localMembersFilter && <TextField source="joined_local_members" />}
        {stateEventsFilter && <TextField source="state_events" />}
        {versionFilter && <TextField source="version" />}
        {federateableFilter && <BooleanField source="federatable" />}
        <BooleanField source="public" />
      </Datagrid>
    </List>
  );
};

export const RoomList = connect(mapStateToProps)(FilterableRoomList);
/*
const StyledFormControlLabel = withStyles({
  root: {
    flexDirection: 'column-reverse',
    marginLeft: '0',
    alignItems: 'flex-start',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: '0',
    fontSize: '12.2px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: '400',
    lineHeight: '1',
    letterSpacing: '0.00938em',
    marginTop: '8px',
  },
})(FormControlLabel);

const StyledSwitch = withStyles({
  root: {
    marginLeft: '-10px',
  }
})(Switch);

const StyledCircularProgress = withStyles({
  root: {
    width: '30px !important',
    height: '30px !important',
    padding: '5px',
  }
})(CircularProgress);
*/
