import React, { useState, useEffect } from "react";
import {Loading, Title } from 'react-admin';
import { ErrorComponent } from "./Error";
import authProvider from "../synapse/authProvider";
import { getServerConfig } from "../helper/serverConfig";

export const MatrixDimensionIframe = props => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const scalarToken = localStorage.getItem("scalar_token");
    const dimensionUrl = getServerConfig("dimension_url");

    const onCreateScalarToken = (token, error) => {
        setLoading(false)
        if (token) {
            window.location.reload();
            return;
        }
        setError(error || new Error('Failed to create scalar token'));
    }
    useEffect(() => {
        if(!scalarToken) {
            setLoading(true)
            authProvider.createScalarToken(onCreateScalarToken);
            return;
        }

        // check connection to dimension server
        authProvider.connectDimensionServer(scalarToken, (connected) => {
            setLoading(false)
            if(!connected){
                setLoading(true)
                authProvider.createScalarToken(onCreateScalarToken);
            }
        });
        // eslint-disable-next-line 
    }, [])

    const pageTitle = <Title title={'resources.matrix_stickers.name'} />;
    if (loading) return <div>
        { pageTitle }
        <Loading />
    </div>;

    // eslint-disable-next-line 
    if (error) return <div>
        { pageTitle }
        <ErrorComponent error={error} />
    </div>;

    return <iframe title="matrix-dimension"
                src={`${dimensionUrl}/riot-app/admin/stickerpacks/?pid=ctalk_admin&scalar_token=${scalarToken}`}
                   width="100%" height="100%" frameBorder={0}> { pageTitle } </iframe>;
};
