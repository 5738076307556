import { fetchUtils } from "react-admin";
import { env } from "../env";

export const HOME_SERVER_NAME = "home_server_name";

export const defaultServerOption = [
  {
    server_name: env.REACT_APP_BRAND,
    base_url: env.REACT_APP_SERVER,
  },
];

let serverConfigs = {};

export async function fetchHomeseverOptions() {
  let url =
    env.REACT_APP_PREFIX_URL_FETCH_HOMESERVERS || window.location.origin;
  const homeServerOptionsUrl = `${url}/homeservers`;
  return fetchUtils
    .fetchJson(homeServerOptionsUrl, { method: "GET" })
    .catch(error => {
      return Promise.reject(error);
    });
}

export async function setServerConfigs(homeServerUrl, serverName) {
  const wellKnownUrl = `${homeServerUrl}/.well-known/matrix/client`;
  try {
    const fetchData = (
      await fetchUtils.fetchJson(wellKnownUrl, { method: "GET" })
    )?.json;
    const home_server_url = fetchData["m.homeserver"]?.base_url?.endsWith("/")
      ? fetchData["m.homeserver"]?.base_url.slice(0, -1)
      : fetchData["m.homeserver"]?.base_url;
    const configs = {
      home_server_url: home_server_url ?? "",
      home_server_name:
        fetchData["m.homeserver"]?.base_url
          ?.replace(/^https?:\/\//, "")
          .replace(/\/$/, "") ?? "",
      dimension_url: fetchData["integrations"]?.base_url ?? "",
      auth_server_url: fetchData["auth_server"]?.base_url ?? "",
      bot_gateway_url: fetchData["bot_gateway"]?.base_url ?? "",
      brand: serverName,
    };
    serverConfigs = Object.keys({ ...serverConfigs, ...configs }).reduce(
      (res, key) => ({
        ...res,
        [key]: configs[key] || serverConfigs[key],
      }),
      {}
    );
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

export function getServerConfigsByDefault() {
  // get all config from env
  serverConfigs = {
    home_server_url: env.REACT_APP_SERVER ?? "",
    home_server_name: env.REACT_APP_SERVER_NAME ?? "",
    dimension_url: env.REACT_APP_MATRIX_DIMENSION_SERVER ?? "",
    auth_server_url: env.REACT_APP_AUTH_SERVER ?? "",
    bot_gateway_url: env.REACT_APP_PUBLIC_API_SERVER ?? "",
    brand: env.REACT_APP_BRAND ?? "",
  };
}

export async function fetchServerConfigs() {
  const home_server_name = localStorage.getItem(HOME_SERVER_NAME);
  if (!home_server_name) {
    const bse_url_key = localStorage.getItem("base_url");
    if (bse_url_key) {
      getServerConfigsByDefault();
    }
    return;
  }
  try {
    const listServers = (await fetchHomeseverOptions())?.json;
    const hs_base_url = listServers?.find(
      e => e.server_name === home_server_name
    )?.base_url;
    if (hs_base_url) {
      await setServerConfigs(hs_base_url, home_server_name);
    } else {
      getServerConfigsByDefault();
    }
  } catch (e) {
    console.log(e);
    getServerConfigsByDefault();
  }
}

export function getServerConfig(key) {
  return serverConfigs[key];
}

export function setTitle(brand) {
  if (!brand) {
    brand = getServerConfig("brand") || env.REACT_APP_BRAND;
  }
  document.title = `${brand} - Admin`;
}
