import ViewListIcon from "@material-ui/icons/ViewList";
import React, { useCallback, useState } from "react";
import { FormDataConsumer, FormTab, useTranslate, Button } from "react-admin";
import Switch from "@material-ui/core/Switch";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useForm, Field } from "react-final-form";
import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";
import PropTypes from "prop-types";
import { CallDirectOptions } from "../../enum/ESetting";
import { FieldLabel } from "./SettingsStyle";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { isInt } from "class-validator";
import { removeLeadingZero } from "../../helper/utils";

export const GeneralSettingKeys = {
  FOLDER_LIMIT_ROOMS: "FOLDER_LIMIT_ROOMS",
};

const initValidations = {
  [GeneralSettingKeys.FOLDER_LIMIT_ROOMS]: "",
};

const SEARCH_MESSAGE_INDEX_MODES = [
  { key: "Sequential", value: "SEQUENTIAL" },
  { key: "Spread", value: "SPREAD" },
];
const useStyles = makeStyles({
  smallText: {
    fontSize: "0.875rem",
  },
  helperText: {
    marginTop: "10px",
  },
});

export const GeneralSettings = props => {
  const translate = useTranslate();
  const form = useForm();
  const [openDialogCallOptions, setOpenDialogCallOptions] = useState(false);
  const classes = useStyles();
  const [validations, setValidations] = useState(initValidations);

  const dialogActionCallOptions = () => {
    setOpenDialogCallOptions(false);
  };

  const changeCallOptions = event => {
    const selectedValue = event.target.checked;
    let optionChange;
    if (selectedValue) {
      optionChange = [...form.getState().values.CALL_DIRECT_VIA, event.target.name];
    } else {
      optionChange = form.getState().values.CALL_DIRECT_VIA?.filter(e => e !== event.target.name);
    }
    if (optionChange?.length === 0) {
      setOpenDialogCallOptions(true);
    } else {
      form.change("CALL_DIRECT_VIA", optionChange);
    }
  };

  const handleMessageErr = (value, field) => {
    if (!value?.length && field === GeneralSettingKeys.FOLDER_LIMIT_ROOMS) {
      return `resources.settings.validations.required`;
    }
    if (!isInt(+value)) {
      return `resources.settings.validations.not_integer`;
    }
    if (!(+value >= 100 && +value <= 300) && field === GeneralSettingKeys.FOLDER_LIMIT_ROOMS) {
      return `resources.settings.validations.range_rooms_in_folder`;
    }
    return "";
  };

  const handleChangeNumber = e => {
    let { name, value } = e.target;
    onValueChange(name, value);
    setValidations({
      ...validations,
      [name]: handleMessageErr(value, name),
    });
    props.onChange(!!handleMessageErr(value, name));
  };

  const onValueChange = (name, valueInput) => {
    let value = valueInput + ''; // convert to String
    value = removeLeadingZero(value);
    form.change(name, value);
  };

  const onChangeSearchMod = useCallback(
    e => {
      let { name, value } = e.target;

      form.change(name, value);
    },
    [form]
  );

  return (
    <FormTab {...props} label="resources.settings.tabs.general" icon={<ViewListIcon />}>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            <div>
              <div>
                <Field name="CALL_DIRECT_VIA">{() => null}</Field>
                <FieldLabel>{translate("resources.settings.fields.call_direct_via")}</FieldLabel>
              </div>
              <StyledFormGroup>
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={formData.CALL_DIRECT_VIA?.includes(CallDirectOptions.matrix_turn_server)}
                      onChange={changeCallOptions}
                      name={CallDirectOptions.matrix_turn_server}
                      color="primary"
                    />
                  }
                  label={translate("resources.settings.fields.matrix_turn_server")}
                />
                <StyledFormControlLabel
                  control={
                    <Switch
                      checked={formData.CALL_DIRECT_VIA?.includes(CallDirectOptions.jitsi_meet)}
                      onChange={changeCallOptions}
                      name={CallDirectOptions.jitsi_meet}
                      color="primary"
                    />
                  }
                  label={translate("resources.settings.fields.jitsi_meet")}
                />
              </StyledFormGroup>
              <Dialog
                onClose={dialogActionCallOptions}
                aria-labelledby="simple-dialog-title"
                open={openDialogCallOptions}
              >
                <DialogTitle id="alert-dialog-title">
                  {translate("resources.settings.validations.required_call_via_title")}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {translate("resources.settings.validations.required_call_via_content")}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={dialogActionCallOptions} label="Ok"></Button>
                </DialogActions>
              </Dialog>
              <div>
                <Field name="FOLDER_LIMIT_ROOMS">{() => null}</Field>
                <FieldLabel>{translate("resources.settings.fields.chat_folder")}</FieldLabel>
                <StyledTextField
                  id="FOLDER_LIMIT_ROOMS"
                  label={translate("resources.settings.fields.limit_rooms_in_folder")}
                  name="FOLDER_LIMIT_ROOMS"
                  value={formData.FOLDER_LIMIT_ROOMS}
                  onChange={handleChangeNumber}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  error={validations[GeneralSettingKeys.FOLDER_LIMIT_ROOMS]}
                  helperText={
                    validations[GeneralSettingKeys.FOLDER_LIMIT_ROOMS]
                      ? translate(validations[GeneralSettingKeys.FOLDER_LIMIT_ROOMS])
                      : null
                  }
                />
              </div>
              <div>
                <Field name="SEARCH_MESSAGE_INDEX_MODE">{() => null}</Field>
                <FieldLabel>{translate("resources.settings.fields.search_message_index_mode")}:</FieldLabel>
                <p className={classes.smallText}>{translate(`resources.settings.fields.effected_mobile_only`)}</p>
                <FormControl sx={{ marginTop: "10px" }}>
                  <Select
                    labelId="search_message_index_mod"
                    name="SEARCH_MESSAGE_INDEX_MODE"
                    value={formData.SEARCH_MESSAGE_INDEX_MODE}
                    size="small"
                    onChange={onChangeSearchMod}
                  >
                    {SEARCH_MESSAGE_INDEX_MODES?.map(option => (
                      <MenuItem key={option.key} value={option.value}>
                        {option.key}
                      </MenuItem>
                    ))}
                  </Select>
                  <div className={classes.helperText}>
                    <p className="MuiFormHelperText-root MuiFormHelperText-sizeSmall MuiFormHelperText-contained MuiFormHelperText-filled">
                      {translate(
                        `resources.settings.fields.helper_text_for_${formData.SEARCH_MESSAGE_INDEX_MODE?.toLowerCase()}`
                      )}
                    </p>
                  </div>
                </FormControl>
              </div>
            </div>
          );
        }}
      </FormDataConsumer>
    </FormTab>
  );
};

GeneralSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
};

const StyledFormGroup = withStyles({
  root: {
    marginLeft: "20px",
  },
})(FormGroup);

const StyledFormControlLabel = withStyles({
  root: {
    marginBottom: "20px",
  },
})(FormControlLabel);

const StyledTextField = styled(TextField)({
  "&.MuiTextField-root": {
    marginBottom: "30px",
  },
});
