import React from "react";
import { Box } from "@mui/material";
import { Field } from "react-final-form";
import { BotWhitelistIPs } from "./BotWhitelistIPs";
import { BooleanInput, number, regex, required, TextInput, useTranslate } from "react-admin";
import { ContentWrapper, SubTitle } from "../BotUsage";
import { getServerConfig } from "../../../../helper/serverConfig";

const BotUsageGeneral = ({ onWhitelistIpsChange, ...props }) => {
  const translate = useTranslate();

  return <>
    <h5>{translate("resources.bots.fields.usage_label_general")}</h5>
    <ContentWrapper>
      {/* Security */}
      <SubTitle>{translate("resources.bots.fields.usage_label_security_settings")}</SubTitle>
      <Box sx={{ color: "gray", fontSize: "0.8em" }}>
        {translate("resources.bots.fields.usage_label_security_settings_des", { brand: getServerConfig("brand") })}
      </Box>
      <Field name="configs.whitelist_ips">{() => null}</Field>
      <BotWhitelistIPs {...props} onWhitelistIpsChange={onWhitelistIpsChange} />
      {/* Encryption */}
      <SubTitle>{translate("resources.bots.fields.usage_label_encryption")}</SubTitle>
      <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: "10px" }}>
        {translate("resources.bots.fields.helper_encryption")}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "0 0 10px 15px" }}>
        <BooleanInput
          source="configs.encryption_direct_messages"
          label="resources.bots.fields.allow_encryption_direct_messages"
          helperText="resources.bots.fields.helper_encryption_direct_messages"
        />
        <BooleanInput
          source="configs.encryption_room_messages"
          label="resources.bots.fields.allow_encryption_room_messages"
          helperText="resources.bots.fields.helper_encryption_room_messages"
        />
      </Box>
      {/* Direct message */}
      <SubTitle>{translate("resources.bots.fields.usage_label_direct_message")}</SubTitle>
      <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
        <TextInput
          source="configs.limit_direct_messages"
          variant="outlined"
          type="number"
          label="resources.bots.fields.limit_direct_messages"
          validate={[
            required(),
            number(),
            regex(/^[1-9]\d*$/, "resources.common.validations.regex_positive_integer"),
          ]}
        />
        <BooleanInput
          source="configs.no_error_over_limit"
          label="resources.bots.fields.no_error_over_limit"
        />
      </Box>
    </ContentWrapper>
  </>;
};

export default BotUsageGeneral;
