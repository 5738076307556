import React, { useState, useEffect } from "react";
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Button as ButtonMui,
  ThemeProvider,
} from "@mui/material";
import { useTranslate, Button } from "react-admin";
import DeleteTwoToneIcon from "@material-ui/icons/DeleteTwoTone";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Close";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { withStyles } from "@material-ui/core/styles";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormHelperText from "@material-ui/core/FormHelperText";
import muiTheme from "../../../../helper/muiTheme";
import { isValidIP } from "../../../../helper/utils";
import PropTypes from "prop-types";

export const BotWhitelistIPs = ({ ...props }) => {
  const translate = useTranslate();
  const [ips, setIps] = useState([]);
  // CreateUpdate
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  // Confirm Delete
  const [isOpenDialogDelete, setIsOpenDialogDelete] = useState(false);
  const [recordClick, setRecordClick] = useState(null);
  const [validations, setValidations] = useState({
    isValid: false,
    messageErr: "",
  });

  useEffect(() => {
    if (props.bot.configs.whitelist_ips) {
      setIps(props.bot.configs.whitelist_ips.split(","));
    }
  }, [props.bot]);

  useEffect(() => {
    props.onWhitelistIpsChange(ips);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ips]);

  const onDeleteRecord = () => {
    setIps(ips.filter(i => i !== recordClick));
    onCloseDialogDelete();
  };

  const onAddRecord = () => {
    setIps([...ips, recordClick]);
    onCloseDialog();
  };

  const onCloseDialogDelete = () => {
    setIsOpenDialogDelete(false);
    setRecordClick(null);
  };

  const onCloseDialog = () => {
    setIsOpenDialog(false);
    setRecordClick(null);
    setValidations({
      isValid: false,
      messageErr: "",
    });
  };

  const onValueIpChange = value => {
    const valueInput = value;
    setRecordClick(valueInput);
    const isValid =
      !value.length ||
      value.length > 255 ||
      /^\s*$/.test(value?.trim()) ||
      !isValidIP(value) ||
      ips?.includes(valueInput);
    setValidations({
      isValid,
      messageErr: handleMessageErr(valueInput),
    });
  };

  const handleMessageErr = (value) => {
    if (!value.length) {
      return `resources.room_whitelist.validation.required_ip`;
    }
    if (/^\s*$/.test(value?.trim())) {
      return `resources.room_whitelist.validation.invalid_ip`;
    }
    if (value.length > 255) {
      return `resources.room_whitelist.validation.invalid_length`;
    }
    if (!isValidIP(value)) {
      return `resources.room_whitelist.validation.invalid_ip_format`;
    }
    if (ips?.includes(value)) {
      return `resources.room_whitelist.validation.existed_ip`;
    }
  };

  return (
    <ThemeProvider theme={muiTheme}>
      <Grid item xs={12}>
        <TableContainer>
          <Table>
            <TableBody>
              {ips.map((row) => (
                <TableRow key={row}>
                  <TableCell align="left">{row}</TableCell>
                  <TableCell sx={{ pr: 1 }} align="right">
                    <IconButton
                      color="error"
                      size="small"
                      onClick={() => {
                        setIsOpenDialogDelete(true);
                        setRecordClick(row);
                      }}
                    >
                      <DeleteTwoToneIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid sx={{ display: "flex", flexDirection: "row-reverse" }}>
          <ButtonMui
            variant="text"
            sx={{ fontSize: "13px" }}
            onClick={() => {
              setIsOpenDialog(true);
            }}
          >
            + Add IP address
          </ButtonMui>
        </Grid>
        {/* Dialog Create Update */}
        {
          <StyledDialog
            onClose={onCloseDialog}
            aria-labelledby="reset-dialog-title"
            open={isOpenDialog}
          >
            <DialogTitle id="reset-dialog-title">
              {translate(`resources.bots.dialog.add_new_ip`)}
            </DialogTitle>
            <DialogContent>
              <FormControl
                variant="outlined"
                style={{ width: "100%", padding: "5px 0 5px" }}
                error={validations?.isValid}
                required={true}
              >
                <InputLabel htmlFor="component-outlined">
                  {translate("resources.bots.dialog.label_ip")}
                </InputLabel>
                <StyledOutlinedInput
                  id="ip"
                  value={recordClick}
                  onChange={e => onValueIpChange(e.target.value)}
                  onClick={e => onValueIpChange(e.target.value)}
                  label="IP"
                />
                <FormHelperText id="ip">
                  {validations?.messageErr
                    ? translate(validations?.messageErr)
                    : ""}
                </FormHelperText>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCloseDialog} color="inherit" label="Cancel">
                <CancelIcon />
              </Button>
              <Button
                onClick={onAddRecord}
                color="primary"
                label="Confirm"
                disabled={validations?.isValid || !recordClick}
              >
                <CheckIcon />
              </Button>
            </DialogActions>
          </StyledDialog>
        }
        {/* Dialog Confirm Delete */}
        {
          <StyledDialog
            onClose={onCloseDialogDelete}
            aria-labelledby="reset-dialog-title"
            open={isOpenDialogDelete}
          >
            <DialogTitle id="reset-dialog-title">
              {translate("resources.room_whitelist.actions.title_delete")}{" "}
              {recordClick}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="reset-dialog-description">
                {translate(
                  "resources.room_whitelist.actions.content_confirm_delete"
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={onCloseDialogDelete}
                color="inherit"
                label="Cancel"
              >
                <CancelIcon />
              </Button>
              <Button onClick={onDeleteRecord} color="primary" label="Confirm">
                <CheckIcon />
              </Button>
            </DialogActions>
          </StyledDialog>
        }
      </Grid>
    </ThemeProvider>
  );
};
BotWhitelistIPs.propTypes = {
  bot: PropTypes.object.isRequired,
  onWhitelistIpsChange: PropTypes.func.isRequired,
}
const StyledOutlinedInput = withStyles({
  input: {
    padding: "12px 18px 12px 14px",
  },
})(OutlinedInput);

const StyledDialog = withStyles({
  paperWidthSm: {
    width: "25%",
  },
})(Dialog);
