import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  TextInput,
  FormTab,
  BooleanInput,
  useTranslate,
  Confirm,
  useNotify,
} from "react-admin";
import { Grid, Box, Button } from "@mui/material";
import SpeedIcon from "@material-ui/icons/Speed";
import CircularProgress from "@material-ui/core/CircularProgress";
import SuccessIcon from "@material-ui/icons/Check";
import FailedIcon from "@material-ui/icons/Close";
import Autorenew from "@material-ui/icons/Autorenew";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useForm, Field } from "react-final-form";
import Input from "@mui/material/Input";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import { useStyles } from "./BotProfile";
import { truncate, randomString } from "../../../helper/utils";
import botProvider from "../../../synapse/botProvider";
import { createSignature } from "../../../helper/bot";
import { isUrl } from "../../../utils/common";
import BotUsageGeneral from "./BotUsage/BotUsageGeneral";
import { getServerConfig } from "../../../helper/serverConfig";

export const SubTitle = ({ children }) => {
  return (<Box sx={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "10px", fontSize: "0.8rem" }}>
    {children}
  </Box>);
};
export const ContentWrapper = ({ children }) => {
  return <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
    {children}
  </Box>;
};

export const ErrorHelperText = ({ children }) => {
  return <Box sx={{ color: "red", fontSize: "0.8em", marginBottom: "10px" }}>
    {children}
  </Box>;
}

export const SmallHelperText = ({ children }) => {
  return <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: "10px" }}>
    {children}
  </Box>;
}

const checkButtonState = (button, state) => {
  if (state === "success") {
    button.icon = <SuccessIcon />;
    button.color = "success";
    button.label = "Success";
    return;
  } else if (state === "failed") {
    button.icon = <FailedIcon />;
    button.color = "error";
    button.label = "Failed";
    return;
  }
  button.icon = <Autorenew />;
  button.color = "primary";
  button.label = "Test";
};

const BotUsage = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const form = useForm();

  /** Form */
  const [enabledForm, setEnabledForm] = useState(props.bot.configs.enabled_form);
  const [formCallbackUrl, setFormCallbackUrl] = useState(props.bot.configs.form_callback);
  const [formSecret, setFormSecret] = useState("");
  const [generatingSecretKey, setGeneratingSecretKey] = useState(false);
  /** Proxy */
  const [enabledProxy, setEnabledProxy] = useState(props.bot.configs.enabled_proxy);
  const [proxyCallbackUrl, setProxyCallbackUrl] = useState(props.bot.configs.proxy_callback);
  const [proxyToken, setProxyToken] = useState(props.bot.configs.proxy_token);
  /** Command */
  const [enabledCommand, setEnabledCommand] = useState(props.bot.configs.enabled_command);

  /** Common */
  const [testBtnState, setTestBtnState] = useState({ form: "ready", proxy: "ready" });
  const [toolTip, setTooltip] = useState({ secret: "", form: "", proxy: "" });
  const [confirm, setConfirm] = useState({
    isOpen: false,
    title: "",
    content: "",
    type: "",
  });

  const featureList = useMemo(() => [
    { key: 'form', configKey: 'configs.enabled_form', stateFn: setEnabledForm },
    { key: 'command', configKey: 'configs.enabled_command', stateFn: setEnabledCommand },
    { key: 'proxy', configKey: 'configs.enabled_proxy', stateFn: setEnabledProxy },
  ], []);

  const toggleFeature = useCallback((feature, checked) => {
    const find = featureList.find((f) => f.key === feature);
    const others = featureList.filter(f => f.key !== feature);
    if (find) {
      form.change(find.configKey, checked);
      find.stateFn(checked);
    }
    if (checked) {
      others.forEach((f) => {
        form.change(f.configKey, false);
        f.stateFn?.(false);
      })
    }
  }, [form, featureList]);

  useEffect(() => {
    if (props.bot.configs.form_secret) {
      setFormSecret(props.bot.configs.form_secret);
    }
  }, [props.bot]);

  const updateButtonState = useCallback((partials) => {
    setTestBtnState({ ...testBtnState, ...partials });
  }, [testBtnState]);

  const testFormCallbackUrl = useCallback((callbackUrl) => {
    if (testBtnState.form !== "ready") return;
    const mockData = {
      request_id: "dummy_request_id",
      room_id: "!dummy_room_id",
      key_query: "$dummy_key_query",
      created_at: new Date().getTime(),
      response_at: new Date().getTime(),
    };

    const signatureRequest = createSignature(mockData, props.bot.configs.form_secret);

    botProvider.testCallback(callbackUrl, mockData, { signature: signatureRequest })
      .then((res) => {
        let notifyMsg = `Callback ${res.status}`;
        if (res.status === 200 || res.status === 201) {
          notifyMsg = "Callback test successfully";
        }
        notify(notifyMsg, { type: "success" });
        updateButtonState({ form: "success" });
      }).catch((err) => {
      notify(`Callback test failed: ${err.message}`, { type: "error" });
      updateButtonState({ form: "failed" });
    }).finally(() => {
      setTimeout(() => {
        updateButtonState({ form: "ready" });
      }, 5000);
    });
  }, [notify, props.bot?.configs?.form_secret, testBtnState.form, updateButtonState]);

  const testProxyCallbackUrl = useCallback((callbackUrl, token) => {
    if (testBtnState.proxy !== "ready") return;
    const mockData = {
      room_id: "!dummy_room_id",
      event_id: "$dummy_event_id",
      user: "johndoe@example.com",
      text: "Dummy text example",
      created_at: new Date().getTime(),
    };
    botProvider.testCallback(callbackUrl, mockData, { token: token })
      .then((res) => {
        let notifyMsg = `Callback ${res.status}`;
        if (res.status === 200 || res.status === 201) {
          notifyMsg = "Callback test successfully";
        }
        notify(notifyMsg, { type: "success" });
        updateButtonState({ proxy: "success" });
      }).catch((err) => {
      notify(`Callback test failed: ${err.message}`, { type: "error" });
      updateButtonState({ proxy: "failed" });
    }).finally(() => {
      setTimeout(() => {
        updateButtonState({ proxy: "ready" });
      }, 5000);
    });
  }, [notify, testBtnState.proxy, updateButtonState]);

  const onGenerate = useCallback((event) => {
    event.preventDefault();
    setConfirm({
      isOpen: true,
      title: translate("resources.bots.dialog.label_re_generate_secret"),
      content: translate("resources.bots.dialog.confirm_re_generate_secret"),
      type: "form_secret",
    });
  }, [translate, setConfirm]);

  const generateSecretKey = useCallback(() => {
    setGeneratingSecretKey(true);
    const newSecretKey = randomString(30);
    setFormSecret(newSecretKey);
    form.change("configs.form_secret", newSecretKey);
    // Set to bot
    setGeneratingSecretKey(false);
  }, [form]);

  const handleDialogClose = useCallback(() =>
      setConfirm({
        isOpen: false,
        title: "",
        content: "",
        type: "",
      }),
    [setConfirm],
  );

  const handleConfirm = useCallback(() => {
    if (confirm?.type === "form_secret") {
      generateSecretKey();
      handleDialogClose();
    }
  }, [confirm?.type, generateSecretKey, handleDialogClose]);

  const onWhitelistIpsChange = useCallback((listIps) => {
    const whitelistIps = listIps?.length ? listIps.join(",") : "";
    form.change("configs.whitelist_ips", whitelistIps);
  }, [form]);

  const getCallbackUrl = useCallback((authUrl, botToken, postFix) => {
    return `${authUrl}/callback/bot/${botToken}/${postFix}`;
  }, []);

  let testButtons = {
    form: {},
    proxy: {},
  };
  checkButtonState(testButtons.form, testBtnState.form);
  checkButtonState(testButtons.proxy, testBtnState.proxy);

  return (
    <FormTab {...props} label="resources.bots.tabs.usage" icon={<SpeedIcon />}>
      {!props.bot?.id ? (
        <div
          style={{
            margin: "75px auto 100px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={50} thickness={2} />
        </div>
      ) : (
        <Grid
          container
          width="100%"
          spacing={2}
          // display="flex"
          // justifyContent="space-between"
        >
          <Grid item xs={12} md={10}>
            {/* General */}
            <BotUsageGeneral onWhitelistIpsChange={onWhitelistIpsChange} {...props} />
            {/* Form */}
            <h5>{translate("resources.bots.fields.usage_label_form")}</h5>
            <BooleanInput
              source="configs.enabled_form"
              label="resources.bots.fields.enabled_form"
              helperText="resources.bots.fields.enabled_form_desc"
              onChange={(checked) => toggleFeature('form', checked)}
            />
            {enabledForm && <ContentWrapper>
              { /* Form callback URL */}
              <Box sx={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "10px", fontSize: "0.8rem" }}>
                {translate("resources.bots.fields.usage_label_callback_url")}
              </Box>
              <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: "10px" }}>
                {translate("resources.bots.fields.usage_label_form_callback_url_desc", { brand: getServerConfig("brand") })}
              </Box>
              <Grid display="flex" container width="100%" spacing={1}
                    style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <Grid item width="80%" xs={10} md={9}>
                  <TextInput
                    fullWidth
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    source="configs.form_callback"
                    variant="outlined"
                    label="resources.bots.fields.usage_label_callback_url"
                    onChange={(event) => {
                      updateButtonState({ form: "ready" });
                      setFormCallbackUrl(event.target.value);
                    }}
                    helperText={false}
                  />
                  {
                    (enabledForm && !formCallbackUrl) &&
                    <ErrorHelperText>{translate("resources.settings.validations.required")}</ErrorHelperText>
                  }
                </Grid>
                <Grid xs={1} md={2}>
                  <Button
                    style={{ width: "135px", marginTop: "-12px" }}
                    color={testButtons.form.color}
                    variant="contained"
                    startIcon={testButtons.form.icon}
                    onClick={() => testFormCallbackUrl(formCallbackUrl)}
                    disabled={!formCallbackUrl || !isUrl(formCallbackUrl)}
                  >{testButtons.form.label}</Button>
                </Grid>
              </Grid>
              <div className={classes.copyClipboardBox} style={{ marginBottom: "25px" }}>
              <span className="MuiFormHelperText-root MuiFormHelperText-filled MuiFormHelperText-marginDense">{
                translate("resources.bots.fields.usage_label_callback_url_helper", {
                  callback_url: truncate(getCallbackUrl(getServerConfig("auth_server_url"), props.botToken, "form"), 75, "..."),
                })
              }</span>
                <div className={classes.actionBox} style={{ height: "unset" }}>
                  <CopyToClipboard text={getCallbackUrl(getServerConfig("auth_server_url"), props.botToken, "form")}>
                <span
                  className={classes.copyClipboard}
                  onClick={event => {
                    event.preventDefault();
                    setTooltip({ ...toolTip, form: translate("resources.users.action.copied") });
                    setTimeout(() => {
                      setTooltip({ ...toolTip, form: "" });
                    }, 1000);
                  }}
                      onKeyDown={() => {}}
                      role='button'
                >
                  <Tooltip title={toolTip.form} open={!!toolTip.form} placement="top">
                    <FileCopyIcon style={{ fontSize: "23" }} />
                  </Tooltip>
                </span>
                  </CopyToClipboard>
                </div>
              </div>
              { /* Form Secret */}
              <Box sx={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "10px", fontSize: "0.8rem" }}>
                {translate("resources.bots.fields.usage_label_credentials")}
              </Box>
              <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: "10px" }}>
                {translate("resources.bots.fields.usage_label_credentials_desc")}
              </Box>
              <div className={classes.copyClipboardBox}>
                <Field name="configs.form_secret">{() => null}</Field>
                <FormControl fullWidth variant="standard">
                  <Input
                    readOnly={true}
                    value={formSecret ? truncate(formSecret, 30) : translate("resources.bots.validations.no_form_secret")}
                    disableUnderline={true}
                    className={[generatingSecretKey && classes.blurText].join(" ")}
                  />
                </FormControl>
                <div className={classes.actionBox} style={{ height: "unset" }}>
                  {!generatingSecretKey && (
                    <CopyToClipboard text={formSecret}>
                    <span
                      className={classes.copyClipboard}
                      onClick={event => {
                        event.preventDefault();
                        setTooltip({ ...toolTip, secret: translate("resources.users.action.copied") });
                        setTimeout(() => {
                          setTooltip({ ...toolTip, secret: "" });
                        }, 1000);
                      }}
                      onKeyDown={() => {}}
                      role='button'
                    >
                      <Tooltip
                        title={formSecret ? toolTip.secret : translate("resources.users.action.empty")}
                        open={!!toolTip.secret}
                        placement="top"
                      >
                        <FileCopyIcon style={{ fontSize: "23" }} />
                      </Tooltip>
                    </span>
                    </CopyToClipboard>
                  )}
                  <span
                    className={classes.randomPassword}
                    onClick={event => onGenerate(event)}
                    onKeyDown={() => {
                    }}
                  >
                <Tooltip
                  title={translate("resources.users.action.generate")}
                  placement="top"
                >
                  <Autorenew
                    className={[generatingSecretKey && classes.spin].join(" ")}
                    style={{
                      fontSize: "25",
                      animationDuration: `1s`,
                      animationDelay: `0.5s`,
                      animationIterationCount: "infinite",
                    }}
                  />
                </Tooltip>
              </span>
                </div>
              </div>
            </ContentWrapper>}
            {/* Proxy */}
            <h5>{translate("resources.bots.fields.usage_label_proxy")}</h5>
            <BooleanInput
              source="configs.enabled_proxy"
              label="resources.bots.fields.enabled_proxy"
              helperText="resources.bots.fields.enabled_proxy_desc"
              onChange={(checked) => toggleFeature('proxy', checked)}
            />
            {enabledProxy && <ContentWrapper>
              <Box sx={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "10px", fontSize: "0.8rem" }}>
                {translate("resources.bots.fields.usage_label_callback_url")}
              </Box>
              <SmallHelperText>
                {translate("resources.bots.fields.usage_label_form_callback_url_desc", { brand: getServerConfig("brand") })}
              </SmallHelperText>
              <Grid display="flex" container width="100%" spacing={1}
                    style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <Grid item width="80%" sx={8}>
                  <TextInput
                    fullWidth
                    style={{
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                    source="configs.proxy_callback"
                    variant="outlined"
                    label="resources.bots.fields.usage_label_callback_url"
                    onChange={(event) => {
                      updateButtonState({ proxy: "ready" });
                      setProxyCallbackUrl(event.target.value);
                    }}
                    required={enabledProxy}
                    helperText={false}
                  />
                  {
                    (enabledProxy && !proxyCallbackUrl) &&
                    <ErrorHelperText>{translate("resources.settings.validations.required")}</ErrorHelperText>
                  }
                </Grid>
                <Grid sx={2}>
                  <Button
                    style={{ width: "135px", marginTop: "-12px" }}
                    color={testButtons.proxy.color}
                    variant="contained"
                    startIcon={testButtons.proxy.icon}
                    onClick={() => testProxyCallbackUrl(proxyCallbackUrl, proxyToken)}
                    disabled={!proxyCallbackUrl || !isUrl(proxyCallbackUrl)}
                  >{testButtons.proxy.label}</Button>
                </Grid>
              </Grid>
              <div className={classes.copyClipboardBox} style={{ marginBottom: "25px" }}>
                  <span className="MuiFormHelperText-root MuiFormHelperText-filled MuiFormHelperText-marginDense">{
                    translate("resources.bots.fields.usage_label_callback_url_helper", {
                      callback_url: truncate(getCallbackUrl(getServerConfig("auth_server_url"), props.botToken, "proxy"), 75, "..."),
                    })
                  }</span>
                <div className={classes.actionBox} style={{ height: "unset" }}>
                  <CopyToClipboard text={getCallbackUrl(getServerConfig("auth_server_url"), props.botToken, "proxy")}>
                      <span
                        className={classes.copyClipboard}
                        onClick={event => {
                          event.preventDefault();
                          setTooltip({ ...toolTip, proxy: translate("resources.users.action.copied") });
                          setTimeout(() => {
                            setTooltip({ ...toolTip, proxy: "" });
                          }, 1000);
                        }}
                        onKeyDown={() => {
                        }}
                      >
                        <Tooltip title={toolTip.proxy} open={!!toolTip.proxy} placement="top">
                          <FileCopyIcon style={{ fontSize: "23" }} />
                        </Tooltip>
                      </span>
                  </CopyToClipboard>
                </div>
              </div>
              <Box sx={{ whiteSpace: "nowrap", fontWeight: "bold", marginBottom: "10px", fontSize: "0.8rem" }}>
                {translate("resources.bots.fields.usage_label_proxy_token")}
              </Box>
              <Box sx={{ color: "gray", fontSize: "0.8em", marginBottom: "10px" }}>
                {translate("resources.bots.fields.usage_label_proxy_token_desc")}
              </Box>
              <TextInput
                fullWidth
                style={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
                source="configs.proxy_token"
                variant="outlined"
                label="resources.bots.fields.usage_label_proxy_token"
                onChange={(event) => {
                  updateButtonState({ proxy: "ready" });
                  setProxyToken(event.target.value);
                }}
              />
            </ContentWrapper>}
            {/* Commands */}
            <h5>{translate("resources.bots.fields.usage_label_command")}</h5>
            <BooleanInput
              source="configs.enabled_command"
              label="resources.bots.fields.enabled_command"
              helperText="resources.bots.fields.enabled_command_desc"
              onChange={(checked) => toggleFeature('command', checked)}
            />
            {enabledCommand && <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
              <BooleanInput
                source="configs.command_jenkins"
                label="resources.bots.fields.enable_jenkins_command"
              />
              { /*
                <BooleanInput
                  source="configs.command_jira"
                  label="resources.bots.fields.enable_jira_command"
                />
                <BooleanInput
                  source="configs.command_github"
                  label="resources.bots.fields.enable_github_command"
                />
                <BooleanInput
                  source="configs.command_system"
                  label="resources.bots.fields.enable_system_command"
                />
                <BooleanInput
                  source="configs.command_custom"
                  label="resources.bots.fields.enable_custom_command"
                />
                */}
            </Box>
            }
          </Grid>
          <Confirm
            isOpen={confirm.isOpen}
            title={confirm.title}
            content={confirm.content}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </Grid>
      )}
    </FormTab>
  );
};

BotUsage.propTypes = {
  botToken: PropTypes.string.isRequired,
};

export default BotUsage;
