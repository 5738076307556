import { stringify } from "query-string";
import HttpAuth from "../utils/httpAuth";

function getSearchOrder(order) {
  if (order === "DESC") {
    return "b";
  } else {
    return "f";
  }
}

const roleProvider = {
  getList(res, params) {
    const { name } = params.filter;
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const from = (page - 1) * perPage;
    const query = {
      size: perPage,
      page: page,
      name: name,
      order_by: field,
      dir: getSearchOrder(order),
    };
    return HttpAuth.get(`/roles?${stringify(query)}`).then(({ json }) => ({
      data: json["items"].map((item, index) => res.map(item, index, from)),
      total: res.total(json, from, perPage),
    }));
  },

  getListRoles() {
    const query = {
      size: 100,
      page: 1,
      order_by: "name",
      dir: "f",
    };
    return HttpAuth.get(`/roles?${stringify(query)}`).then(
      ({ json }) => json["items"]
    );
  },

  getRole(roleId) {
    return HttpAuth.get(`/roles/${roleId}`).then(({ json }) => ({
      data: json,
    }));
  },

  getPermissions() {
    return HttpAuth.get("/roles/permissions").then(({ json }) => ({
      data: json["items"],
    }));
  },

  getUsers(roleId) {
    const query = {
      roleId: roleId,
    };
    return HttpAuth.get(
      `/roles/users?${roleId ? stringify(query) : null}`
    ).then(({ json }) => ({
      data: json["items"].map(item => item),
    }));
  },

  createRole(data) {
    return HttpAuth.post("/roles", data);
  },

  updateRole(data) {
    return HttpAuth.put(`/roles/${data.roleId}`, data);
  },

  deleteRole(roleId) {
    return HttpAuth.delete(`/roles/${roleId}`);
  },

  getMyRole() {
    // Call this func from [permissionsSaga] but we can not use variable [this.base_url] (undefined)
    return HttpAuth.get("/roles/my-role").then(({ json }) => json);
  },
};

export default roleProvider;
