import React, { useState } from "react";
import { useNotify, useTranslate } from "react-admin";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import SaveIcon from "@material-ui/icons/Save";
import { FieldLabel } from "../settings/SettingsStyle";
import { LoadingProgress } from "../common/LoadingProgress";
import roomProvider from "../../synapse/roomProvider";
import { isDefaultRotation, RotationConfig } from "../common/RotationConfig";

export const RoomEncryption = (props) => {
    const {data} = props;
    const translate = useTranslate();
    const notify = useNotify();

    const [rotationPeriodMsgs, setRotationPeriodMsgs] = useState(data?.rotation_period_msgs || 'default');
    const [rotationPeriodMsgsInit, setRotationPeriodMsgsInit] = useState(data?.rotation_period_msgs || 'default');

    const [loading, setLoading] = useState(false);

    const onSave = async () => {
        setLoading(true);
        try {
        const content = {
            algorithm: "m.megolm.v1.aes-sha2",
            rotation_period_msgs: isDefaultRotation(rotationPeriodMsgs) ? undefined : rotationPeriodMsgs,
        }
        await roomProvider.setRoomEncryption(props.id, content);
            setRotationPeriodMsgsInit(rotationPeriodMsgs);
            notify("resources.rooms.notifications.change_config_success",{ type: "success" });
        } catch (e) {
            if (e.status >= 400) {
                notify(e.body?.error, "error");
            }
            notify("resources.rooms.notifications.change_config_failed",{ type: "error" });
        } finally {
            setLoading(false);
        }
    }

    const onRotationChange = (value) => {
        setRotationPeriodMsgs(value);
    };

    return <Box  sx={{ paddingY: 2 }}>
        <FieldLabel>
            {translate("resources.settings.fields.rotation_period_messages_title")}
        </FieldLabel>
        <RotationConfig
            translate={translate}
            rotationValueInput={rotationPeriodMsgs}
            helperDescription={"resources.settings.fields.rotation_period_messages_des"}
            onRotationChange={onRotationChange}
        />
        <Box paddingY={2}>
            <Button
                color="primary"
                variant="contained"
                onClick={onSave}
                disabled={loading || rotationPeriodMsgs === rotationPeriodMsgsInit}
                startIcon={loading ? <LoadingProgress size={20} thickness={3}/> : <SaveIcon />}
            >
                {translate("resources.roles.actions.save")}
            </Button>
        </Box>
    </Box>;
};