import React, { useEffect, useState } from "react";
import { FormDataConsumer, FormTab, TextInput, number, required, regex, useTranslate, BooleanInput } from "react-admin";
import { Grid, TextField } from "@mui/material";
import { useForm } from "react-final-form";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { isPositive } from "class-validator";
import BotIcon from "../../customIcons/BotIcon";
import { FieldLabel /* GroupContent */ } from "./SettingsStyle";
import {
  getTimeByMinutes,
  getMinutesByTime,
  bytesToMegabytes,
  megabytesToBytes,
  getHoursByTime,
  getTimeByHours,
  removeLeadingZero,
} from "../../helper/utils";
import { AccordionSummary } from "../common/Accordion";
import PropTypes from "prop-types";

export const EBotSetting = {
  TTL_KEY_SHARE_BOT: "TTL_KEY_SHARE_BOT",
  BOT_FORM_REQUEST_FILE_TYPES: "BOT_FORM_REQUEST_FILE_TYPES",
  BOT_FORM_MAX_REQUEST_FILE_COUNT: "BOT_FORM_MAX_REQUEST_FILE_COUNT",
  BOT_FORM_MAX_REQUEST_FILE_SIZE: "BOT_FORM_MAX_REQUEST_FILE_SIZE",
  BOT_FORM_EXPIRED_TIME: "BOT_FORM_EXPIRED_TIME",
  BOT_FORM_PREVIEW_FILE_EXPIRES_IN: "BOT_FORM_PREVIEW_FILE_EXPIRES_IN",
  BOT_FORM_DATA_SUBMITTED_EXPIRES_IN: "BOT_FORM_DATA_SUBMITTED_EXPIRES_IN",
  BOT_FORM_RESPONSE_EXPIRES_IN: "BOT_FORM_RESPONSE_EXPIRES_IN",
  BOT_FORM_VERIFY_SIGNATURE: "BOT_FORM_VERIFY_SIGNATURE",
  BOT_COMMAND_OAUTH_JIRA_CLIENT_ID: "BOT_COMMAND_OAUTH_JIRA_CLIENT_ID",
  BOT_COMMAND_OAUTH_JIRA_CLIENT_SECRET: "BOT_COMMAND_OAUTH_JIRA_CLIENT_SECRET",
  BOT_COMMAND_OAUTH_GITHUB_CLIENT_ID: "BOT_COMMAND_OAUTH_GITHUB_CLIENT_ID",
  BOT_COMMAND_OAUTH_GITHUB_CLIENT_SECRET: "BOT_COMMAND_OAUTH_GITHUB_CLIENT_SECRET",
};

const FIELDS_CONVERT_TO_HOURS = [
  EBotSetting.BOT_FORM_EXPIRED_TIME,
  EBotSetting.BOT_FORM_RESPONSE_EXPIRES_IN,
  EBotSetting.BOT_FORM_DATA_SUBMITTED_EXPIRES_IN,
];

export const BotSettings = props => {
  const translate = useTranslate();
  const form = useForm();
  const [formViewSettings, setFormViewSettings] = useState({});

  useEffect(() => {
    let mapKeys = {};
    for (const [key, value] of Object.entries(form.getState().values)) {
      if (FIELDS_CONVERT_TO_HOURS.includes(key)) {
        mapKeys = {
          ...mapKeys,
          [key]: getHoursByTime(value),
        };
        continue;
      }
      if (key === EBotSetting.BOT_FORM_PREVIEW_FILE_EXPIRES_IN) {
        mapKeys = {
          ...mapKeys,
          [key]: getMinutesByTime(value),
        };
        continue;
      }
      if (key === EBotSetting.BOT_FORM_MAX_REQUEST_FILE_SIZE) {
        mapKeys = {
          ...mapKeys,
          [key]: bytesToMegabytes(value),
        };
      }
    }
    setFormViewSettings({ ...mapKeys });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = e => {
    let { name, value } = e.target;
    if (!isPositive(value)) {
      onValueChange(name, formViewSettings[name]);
    }
    onValueChange(name, value);
  };

  const onValueChange = (name, valueInput) => {
    let value = removeLeadingZero(valueInput);
    setFormViewSettings({
      ...formViewSettings,
      [name]: value,
    });
    if (FIELDS_CONVERT_TO_HOURS.includes(name)) {
      value = getTimeByHours(value);
      props.onChange(value);
    }
    if (name === EBotSetting.BOT_FORM_PREVIEW_FILE_EXPIRES_IN) {
      value = getTimeByMinutes(value);
      props.onChange(value);
    }
    if (name === EBotSetting.BOT_FORM_MAX_REQUEST_FILE_SIZE) {
      value = megabytesToBytes(value);
      props.onChange(value);
    }
    form.change(name, value);
  };

  return (
    <FormTab {...props} label="resources.settings.tabs.bot" icon={<BotIcon />}>
      <FormDataConsumer>
        {({ formData, ..._ }) => {
          return (
            <Grid container width="100%" spacing={2}>
              <Grid item xs={8} md={6} lg={4}>
                <FieldLabel>{translate("resources.settings.fields.bot_share_settings")}:</FieldLabel>
                <TextInput
                  source={EBotSetting.TTL_KEY_SHARE_BOT}
                  label="resources.settings.fields.ttl_bot_share"
                  variant="outlined"
                  type="number"
                  fullWidth
                  validate={[
                    required(),
                    number(),
                    regex(/^[1-9]\d{0,5}$/, "resources.settings.validations.regex_integer"),
                  ]}
                />
                <FieldLabel>{translate("resources.settings.fields.bot_form_settings")}:</FieldLabel>
                <BooleanInput
                  source={EBotSetting.BOT_FORM_VERIFY_SIGNATURE}
                  label="resources.settings.fields.bot_form_verify_signature"
                  fullWidth
                />
                <TextInput
                  source={EBotSetting.BOT_FORM_REQUEST_FILE_TYPES}
                  label="resources.settings.fields.bot_form_request_file_types"
                  variant="outlined"
                  fullWidth
                  validate={required()}
                />
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row-reverse",
                  }}
                >
                  <AccordionSummary
                    aria-controls="howToUse-content"
                    id="howToUse-header"
                    style={{
                      width: "auto",
                      paddingBottom: "15px",
                      marginTop: "-23px",
                    }}
                    onClick={() => {
                      window.open(
                        "https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types",
                        "_blank",
                        "noopener",
                      );
                    }}
                  >
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {translate("resources.settings.fields.bot_form_request_file_types_helper")}
                    </Typography>
                  </AccordionSummary>
                </div>
                <TextInput
                  source={EBotSetting.BOT_FORM_MAX_REQUEST_FILE_COUNT}
                  label="resources.settings.fields.bot_form_max_request_file_count"
                  variant="outlined"
                  type="number"
                  fullWidth
                  validate={[required(), number(), regex(/^[1-5]$/, "resources.settings.validations.regex_integer")]}
                  disabled
                />
                <StyledTextField
                  id="bot_form_max_request_file_size"
                  label={translate("resources.settings.fields.bot_form_max_request_file_size")}
                  name={EBotSetting.BOT_FORM_MAX_REQUEST_FILE_SIZE}
                  value={formViewSettings[EBotSetting.BOT_FORM_MAX_REQUEST_FILE_SIZE]}
                  onChange={handleChange}
                  onClick={handleChange}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="number"
                  error={!formViewSettings[EBotSetting.BOT_FORM_MAX_REQUEST_FILE_SIZE]}
                  helperText={
                    !formViewSettings[EBotSetting.BOT_FORM_MAX_REQUEST_FILE_SIZE]
                      ? translate("resources.settings.validations.required")
                      : translate("resources.settings.fields.bot_form_max_request_file_size_note")
                  }
                />
                <StyledTextField
                  id="bot_form_expires_in"
                  label={translate("resources.settings.fields.bot_form_expires_in")}
                  name={EBotSetting.BOT_FORM_EXPIRED_TIME}
                  value={formViewSettings[EBotSetting.BOT_FORM_EXPIRED_TIME]}
                  onChange={handleChange}
                  onClick={handleChange}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="number"
                  error={!formViewSettings[EBotSetting.BOT_FORM_EXPIRED_TIME]}
                  helperText={
                    !formViewSettings[EBotSetting.BOT_FORM_EXPIRED_TIME]
                      ? translate("resources.settings.validations.required")
                      : translate("resources.settings.fields.bot_form_expired_note")
                  }
                />
                {/* <StyledTextField
                id="bot_form_preview_expires_in"
                label={translate("resources.settings.fields.bot_form_preview_expires_in")}
                name={EBotSetting.BOT_FORM_PREVIEW_FILE_EXPIRES_IN}
                value={formViewSettings[EBotSetting.BOT_FORM_PREVIEW_FILE_EXPIRES_IN]}
                onChange={handleChange}
                onClick={handleChange}
                variant="outlined"
                size="small"
                required
                fullWidth
                type="number"
                error={!formViewSettings[EBotSetting.BOT_FORM_PREVIEW_FILE_EXPIRES_IN]}
                helperText={!formViewSettings[EBotSetting.BOT_FORM_PREVIEW_FILE_EXPIRES_IN] ? "Required" : ""}
              /> */}
                <StyledTextField
                  id="bot_form_response_expires_in"
                  label={translate("resources.settings.fields.bot_form_response_expires_in")}
                  name={EBotSetting.BOT_FORM_RESPONSE_EXPIRES_IN}
                  value={formViewSettings[EBotSetting.BOT_FORM_RESPONSE_EXPIRES_IN]}
                  onChange={handleChange}
                  onClick={handleChange}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="number"
                  error={!formViewSettings[EBotSetting.BOT_FORM_RESPONSE_EXPIRES_IN]}
                  helperText={
                    !formViewSettings[EBotSetting.BOT_FORM_RESPONSE_EXPIRES_IN]
                      ? translate("resources.settings.validations.required")
                      : translate("resources.settings.fields.bot_form_preview_expired_note")
                  }
                />
                <StyledTextField
                  id="bot_form_data_submitted_expires_in"
                  label={translate("resources.settings.fields.bot_form_data_submitted_expires_in")}
                  name={EBotSetting.BOT_FORM_DATA_SUBMITTED_EXPIRES_IN}
                  value={formViewSettings[EBotSetting.BOT_FORM_DATA_SUBMITTED_EXPIRES_IN]}
                  onChange={handleChange}
                  onClick={handleChange}
                  variant="outlined"
                  size="small"
                  required
                  fullWidth
                  type="number"
                  error={!formViewSettings[EBotSetting.BOT_FORM_DATA_SUBMITTED_EXPIRES_IN]}
                  helperText={
                    !formViewSettings[EBotSetting.BOT_FORM_DATA_SUBMITTED_EXPIRES_IN]
                      ? translate("resources.settings.validations.required")
                      : translate("resources.settings.fields.bot_form_data_submitted_expired_note")
                  }
                />
                {/* <FieldLabel>{translate("resources.settings.fields.bot_command_settings")}:</FieldLabel>
              <GroupContent>
                <FieldLabel>{translate("resources.settings.fields.bot_command_jira_settings")}:</FieldLabel>
                <TextInput
                  source={EBotSetting.BOT_COMMAND_OAUTH_JIRA_CLIENT_ID}
                  label="resources.settings.fields.bot_command_oauth_client_id"
                  variant="outlined"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source={EBotSetting.BOT_COMMAND_OAUTH_JIRA_CLIENT_SECRET}
                  label="resources.settings.fields.bot_command_oauth_client_secret"
                  variant="outlined"
                  fullWidth
                  validate={required()}
                />
                <FieldLabel>{translate("resources.settings.fields.bot_command_github_settings")}:</FieldLabel>
                <TextInput
                  source={EBotSetting.BOT_COMMAND_OAUTH_GITHUB_CLIENT_ID}
                  label="resources.settings.fields.bot_command_oauth_client_id"
                  variant="outlined"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source={EBotSetting.BOT_COMMAND_OAUTH_GITHUB_CLIENT_SECRET}
                  label="resources.settings.fields.bot_command_oauth_client_secret"
                  variant="outlined"
                  fullWidth
                  validate={required()}
                />
              </GroupContent> */}
              </Grid>
            </Grid>
          );
        }}
      </FormDataConsumer>
    </FormTab>
  );
};

BotSettings.propTypes = {
  onChange: PropTypes.func.isRequired,
}

const StyledTextField = styled(TextField)({
  "&.MuiTextField-root": {
    marginBottom: "30px",
  },
});
