import { getCurrentUserId } from "../utils/storage";
import HttpAuth from "../utils/httpAuth";
import HttpHomeserver from "../utils/httpHomeserver";

const userProvider = {
  remove2FA(userId) {
    return HttpAuth.delete(`/user/two-factor/${userId}`);
  },
  removeYBK(userId) {
    return HttpAuth.delete(`/security/yubikey/${userId}`);
  },
  checkEmailExistToUpdate(dto) {
    return HttpAuth.post("/user/email/exist", dto);
  },
  checkPhoneExist(dto) {
    return HttpAuth.post("/user/phone/exist", dto);
  },
  checkAddressExist(dto) {
    return HttpAuth.post("/user/address/exist", dto);
  },
  deactivatedUser(userId, dto) {
    return HttpAuth.put(`/user/deactivate/${userId}`, dto);
  },
  getSecurityStatus(userId) {
    return HttpAuth.get(`/user/security/status/${userId}`);
  },
  async getSelfProfile() {
    const userId = getCurrentUserId();
    const res = await HttpHomeserver.get(
      `/_matrix/client/v3/profile/${userId}`
    );
    res.json.userId = userId;
    return res.json;
  },
  async getSelfUsername() {
    const res = await HttpAuth.get("/user/username");
    return res.json;
  },
  async getSelfThreePId() {
    const res = await HttpHomeserver.get("/_matrix/client/v3/account/3pid");
    return res.json;
  },
  async getSelfTwoFactorStatus() {
    const res = await HttpAuth.get("/user/two-factor");
    return res.json;
  },
  async checkUsernameExist(dto) {
    return HttpAuth.post("/user/username/existed", dto);
  },
  /* Room migration */
  async getOwnerRoomsByUser(userId) {
    const res = await HttpAuth.get(`/user/${userId}/list-owner-rooms`);
    return res.json;
  },
  async deleteMultiDevices(userId, dto) {
    const res = await HttpHomeserver.post(
      `/_synapse/admin/v2/users/${userId}/delete_devices`,
      dto
    );
    return res.json;
  },
};

export default userProvider;
