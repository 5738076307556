import http from "./http";
import { getServerConfig } from "../helper/serverConfig";

const HttpAuth = {
  prepareUrl(urlOrPath) {
    if (urlOrPath.startsWith("http")) {
      return urlOrPath;
    }
    return `${getServerConfig("auth_server_url")}${urlOrPath}`;
  },

  async get(url, headers) {
    return await http.get(this.prepareUrl(url), headers);
  },

  async post(url, body, headers) {
    return await http.post(this.prepareUrl(url), body, headers);
  },

  async put(url, body, headers) {
    return await http.put(this.prepareUrl(url), body, headers);
  },

  async patch(url, body, headers) {
    return await http.patch(this.prepareUrl(url), body, headers);
  },

  async delete(url, headers) {
    return await http.delete(this.prepareUrl(url), headers);
  },
};

export default HttpAuth;
